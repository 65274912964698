import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Container } from "reactstrap";
import { Box, Button, Stack, SvgIcon, Typography, Grid, Snackbar, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress'
import axios from 'axios'
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { Controller, useForm } from 'react-hook-form'
// import { useRouter, withRouter } from 'next/router'
// import { useAlert } from 'react-alert'
import { DataGrid } from '@mui/x-data-grid'
import Tooltip from '@mui/material/Tooltip'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Backdrop } from '@mui/material';

// import { createBrowserHistory } from 'history'
// import { useHistory } from "react-router-dom";
// import { Navigate } from "react-router-dom";

import { useNavigate } from 'react-router-dom';
import CleaningServicesOutlinedIcon from '@mui/icons-material/CleaningServicesOutlined'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import Moment from 'moment'
import InputMask from 'react-input-mask';
import { CancelOutlined, SaveOutlined } from '@mui/icons-material'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "../Button.css"

// const history = useHistory();
const Patient = () => {
  // document.title = "Dashboard | Upzet - React Admin & Dashboard Template";
  document.title = "Patient | LuxBillingEHR";
  // const auth =    localStorage.getItem("Token");
  // const auth = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImN0eSI6IkpXVCJ9.eyJqdGkiOiIwZmRiNWIxMy0wYzExLTQ4ZTQtOTdmMy1hZjhlOGNmOTJlMjgiLCJ2YWxpZCI6IjEiLCJ1c2VyRW1haWwiOiJhbGlAZ21haWwuY29tIiwicHJhY3RpY2VJZCI6IjEiLCJleHBpcnlEYXRlIjoiNy8zLzIwMjgiLCJicm93c2VyIjoiR29vZ2xlIENocm9tZSIsImFwcGxpY2F0aW9uIjoiTHV4QmlsbGluZyIsIklzQWRtaW4iOiJGYWxzZSIsIlByb3ZpZGVySWQiOiIwIiwiZXhwIjoxODQ2MDgwNTE2LCJpc3MiOiJodHRwOi8vd3d3Lkx1eEJpbGxpbmcuY29tIiwiYXVkIjoiaHR0cDovL3d3dy5MdXhCaWxsaW5nLmNvbSJ9.2Uac6a-c-IPRJFzYSvjxpRych9icwhsebjvB2lgZaOs`
  const navigate = useNavigate();
  const [rows, setRows] = useState(null)
  const [orignalRows, setOrignalRows] = useState(null);
  const [refresh, setRefresh] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }

  const Usertype= localStorage.getItem('User_Type')
  console.log('usertype',Usertype)
  const [flastName, setFlastName] = useState('')
  const [ffirstName, setFfirstName] = useState('')
  const [fdob, setFdob] = React.useState('')
  const [fphone, setFphone] = useState('')
  const [femail, setFemail] = useState('')
  const [refreshAssessment, setrefreshAssessment] = useState(false)
  const GETTING_ROWS_LABEL = 'Please wait!! Your data is being loaded..'
  const [NoRowsLabel, setNoRowsLabel] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [Delbyid, setDelbyid] = useState(false);

  const handleDelete = (cell) => {
    // Perform your delete logic here
    const postData = {
      id: Delbyid,
      inactive: true,
    };
    axios
    .post(`${process.env.REACT_APP_API_URL}/Patients/addPatient`, postData, {
      headers,
    })
      .then((response) => {
        setRefresh(!refresh);
        console.log("aaa",response)
      })
      .catch((error) => {
        console.error('There was an error!', error);
      });
    // Close the dialog
    setDialogOpen(false);
  };
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      headerClassName: 'super-app-theme--header',
      sortable: false,
      flex: 0.1,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'gridCell',
      sortable: false,
      flex: 0.25,
      renderCell: (cellValues) => (
        <Button
        style={{ display: "flex", justifyContent: "flex-start"}}
          size="small"
          variant="text"
          color="info"
          onClick={(event) => {
            openPatientRegistration(event, cellValues.row.id)
          }}
        >{`${cellValues.row.lastName}`}</Button>
      ),
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      headerClassName: 'super-app-theme--header',
      sortable: false,
      flex: 0.25,
    },
    {
      field: 'dob',
      headerName: 'DOB',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'gridCell',
      sortable: false,
      flex: 0.2,
    },
    {
      field: 'address',
      headerClassName: 'super-app-theme--header',
      headerName: 'Address',
      // cellClassName: 'gridCell',
      sortable: false,
      flex: 0.3,
    },
    {
      field: 'city',
      headerClassName: 'super-app-theme--header',
      headerName: 'City',
      // cellClassName: 'gridCell',
      sortable: false,
      flex: 0.2,
    },
    {
      field: 'state',
      headerClassName: 'super-app-theme--header',
      headerName: 'State',
      
      sortable: false,
      flex: 0.2,
    },
    {
      field: 'cellPhone',
      headerClassName: 'super-app-theme--header',
      headerName: 'Phone #',
      sortable: true,
      flex: 0.2,
    },
    {
      field: 'status',
      headerClassName: 'super-app-theme--header',
      headerName: 'Status',
      // cellClassName: 'gridCell',
      sortable: false,
      flex: 0.2,
    },
  
  //   {
  //     field: 'inactive',
  //     headerClassName: 'super-app-theme--header',
  //     headerName: 'Delete',
  //     sortable: true,
  //     flex: 0.15,
  //     // hide: true,
  //     // hide: Usertype == 'Admin'?false:true,
      
  //     renderCell: cell => (
      
  //       <>
  //       <DeleteIcon
  //         style={{
  //           marginRight: '5px',
  //           float: 'right',
  //           marginTop: '5px',
  //           color: 'red',
  //           cursor: 'pointer',
  //         }}
  //         color="black"
  //         onClick={() => {setDialogOpen(true)
  //           setDelbyid(cell.row.id)
  //         }}
  //       ></DeleteIcon>

  
  //       <Dialog
  //         open={isDialogOpen}
  //         onClose={() => setDialogOpen(false)}
  //         aria-labelledby="delete-dialog-title"
  //         BackdropComponent={Backdrop}
  //         BackdropProps={{
  //           invisible: true,
  //         }}
  //         PaperProps={{
  //           style: {
  //             marginRight: '5px',
  //           float: 'right',
  //           marginTop: '5px',
  //           color: 'red',
  //           cursor: 'pointer',
  //           },
  //         }}
  //       >
  //        <div style={{
  //   display: 'flex',
  //   flexDirection: 'column',
  //   alignItems: 'center', // Center horizontally
  //   justifyContent: 'center', // Center vertically
  //   borderRadius: '8px',
  //   width: '500px'
  // }}>
    
  //   <ErrorOutlineIcon style={{ fontSize: '100px', color: 'red' }} />
  //   <DialogTitle style={{ fontWeight: 'bold',color:'black',fontSize:' 1.25rem' }}>Are you sure?</DialogTitle>
  //   <h5 style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'normal',color:'#444444' }}>
  //     You won't be able to revert this!
  //   </h5>
  //   <DialogContent>
  //     <DialogContentText>
  
  //       <Grid style={{ marginTop: '1rem' }}>
  //         <Button onClick={() => setDialogOpen(false)} style={{ backgroundColor: '#6c7c84', color: 'white', marginBottom: '1rem' }}>No,cancel!</Button>
  //         <Button  onClick={() => handleDelete(cell)} style={{ backgroundColor: 'red', color: 'white', marginLeft: '1rem', marginBottom: '1rem',border:'1px solid #b4acec' }}>Yes,delete it!</Button>
  //       </Grid>
  //     </DialogContentText>
  //   </DialogContent>
  // </div>
  
  //       </Dialog>
  //     </>
  //     )
  //   }
  {
    field: 'inactive',
    headerClassName: 'super-app-theme--header',
    headerName: 'Delete',
    sortable: true,
    flex: 0.2,
    // hide: true,
    // hide: Usertype == 'Admin'?false:true,

    renderCell: cell => (

      <DeleteIcon
        style={{
          marginRight: '5px',
          float: 'right',
          marginTop: '5px',
          color: 'red',
          cursor: 'pointer'
        }}

        onClick={cellValues => {

          confirmAlert({
            message: 'Do you want to delete the record.',
            closeOnEscape: true,
            closeOnClickOutside: true,
            overlayClassName: 'overlay-custom-class-name',

            buttons: [
              {
                label: 'Yes',

                onClick: () => {
                  const postData = {
                    id: cell.row.id,
                    inactive: true,
                  }
                  axios
                    .post(`${process.env.REACT_APP_API_URL}/Patients/addPatient`, postData, {
                      headers
                    })
                    .then(response => {
                      setRefresh(!refresh)
                      // setOpenNote(false)
                      // console.log('response.data: ', response.data)
                    })
                    .catch(error => {
                      console.error('There was an error!', error)
                    })
                }
              },
              {
                label: 'No'
              }
            ]
          })

        }}
      ></DeleteIcon>
    )
  }
  ]

  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 2,
    minHeight:'5px !important',
    background: 'drak',
    fontSize: '13.4px !important',
    color: 'drak !important',
    borderColor: '#326C1D',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '&.MuiDataGrid-root .custom-column-header .MuiDataGrid-colCellTitle': {
      lineHeight: '20px !important' // Adjust this value to decrease the header height
    },
    '& .super-app-theme--header': {
      backgroundColor: '#377562',
      borderRadius: 0.1,
      color: 'white',
      fontSize: '16px !important',
      // minHeight:'5px !important'
      
      // height: '35px !important',
     
    }, 
   
   

  }
  const gridClasses = {
    columnHeader: 'MuiDataGrid-columnHeaders',
  };
  const openPatientRegistration = (event, id) => {
    navigate('/PatientRegistration', { state: { patientId: id } })

  }
  useEffect(() => {
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/Patients/getPatients`, {
        headers
      }
      )
      .then((response) => {
        setRows(response)
        setOrignalRows(response)
        setLoading(false)
      })

      .catch((error) => {
        console.error('There was an error!', error)
      })

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [refresh])
  const keyDown = (e) => {
    if (e.key === 'Enter') {
      getdata(e)
    }
  }
  const onFilterClear = (event) => {
    setFlastName('')
    setFfirstName('')
    setFdob('')
    setFphone('')
   
    setrefreshAssessment(!refreshAssessment)
  }
 const [Validate ,setValidate]=useState(false)
  const onFilterSearch = (event) => {
    if (!(flastName || ffirstName || fdob || fphone)) {
      setValidate(true)
      return;
    }
  setrefreshAssessment(!refreshAssessment);
  }
  useEffect(() => {
    getdata()
  }, [refreshAssessment])
 

  const getdata = (event) => {
    setRows(null)
    setSubmitting(true)
    // setPatientIdArr([])
    const postData = {
      lastName: flastName,
      firstName: ffirstName,
      dob: fdob,
      cellPhone:fphone
    
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Patients/searchPatient`,
        postData,
        { headers }
      )
      .then((response) => {
        if (response.length == 0) {
          setNoRowsLabel('No Record Found')
        } else {
          setRows(response)
        }
        setSubmitting(false)
      })
      .catch((error) => {
        setSubmitting(true)
        console.error('There was an error!', error)
      })
  }
  useEffect(() => {
    // Target the header cells and header directly and adjust their height
    const headerCells = document.querySelectorAll('.MuiDataGrid-colCell');
    const header = document.querySelector('.MuiDataGrid-header');

    if (headerCells) {
      headerCells.forEach((cell) => {
        cell.style.height = '1px';
      });
    }

    if (header) {
      header.style.height = '1px';
    }
  }, []);
  return (

    <div className="page-content">
    
      <Container >
              <div style={{ width: "100%", marginTop: '5px', marginBottom: '0px' }}>
              <Button
              sx={{ mb: 1 }}
              className="Button"
              onClick={openPatientRegistration}
              variant="contained"
            >
              <AddOutlinedIcon
                fontSize='small'
                style={{
                  color: 'white',
                  paddingRight: '4px',
                  paddingBottom: '2px'
                }}
              ></AddOutlinedIcon>
              Add New
            </Button>
              </div>
              {/* ///////////// */}
              <Grid
          container
          spacing={1}
          item
          xs={12}
          sm={12}
          xl={12}
          style={{
            width: '100%',
            marginBottom: '10px',
            border: '2px solid #326C1D',
            borderRadius: '20px',
            marginTop: '10px',
            paddingBottom: '20px',
            marginLeft: '1px',
            padding: '15px',
            // paddingLeft: '15px',
            backgroundColor: 'white',
          }}
        >
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography style={{ fontSize: '14px' }}>First Name</Typography>
            <Grid item xs={12} >
            <TextField
              onChange={(e) => setFfirstName(e.target.value)}
              value={ffirstName}
              placeholder="Please enter first name"
              onKeyDown={keyDown}
              name="firstName"
              size='small'
              fullWidth
              margin="dense"
            />
          </Grid>
          </Grid>
          
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography style={{ fontSize: '14px' }}>Last Name</Typography>
            <Grid item xs={12} >
            <TextField
              onChange={(e) => setFlastName(e.target.value)}
              value={flastName}
              placeholder="Please enter last name"
              name="lastName"
              size='small'
              onKeyDown={keyDown}
              fullWidth
              margin="dense"
            />
          </Grid>
          </Grid>
       
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography style={{ fontSize: '14px' }}>DOB</Typography>
            <Grid item xs={12} >
            <TextField
              onChange={(e) => setFdob(e.target.value)}
              value={fdob}
              type="date"
              placeholder="Please enter dob"
              name="dob"
              size='small'
              onKeyDown={keyDown}
            
              fullWidth
              margin="dense"
            />
          </Grid>
          </Grid>
         
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography style={{  fontSize: '14px' }}>Phone No</Typography>
            <Grid item xs={12} >
          <InputMask
                    mask="+1 999-999-9999"
                    disabled={false}
                    value={fphone}
                    onKeyDown={keyDown}
                    // style={{ height: '30px' }}
                    onChange={(e) => {
                      // setValue('ssn', e.target.value)
                      setFphone(e.target.value)
                    }}
                  >
                    {() => (
                      <TextField
                        id="outlined-basic"
                        fullWidth
                        size='small'
                        margin="dense"
                        placeholder="Enter Phone NO"
                      />
                    )}
                  </InputMask>
          </Grid>
         
          </Grid>
          <Grid item xs={12}>
          {Validate && <p style={{ color: 'red', fontSize: '14px' }}>Please select a Data For Patient.</p>}
          </Grid>

             
      
          

          <Grid container>
            <Grid item xs={9} sm={9} xl={9}></Grid>
            <Grid item xs={3} sm={3} xl={3}>
              <Button
              className='Button'
                onClick={onFilterSearch}
                variant="gradient"
                style={{
                  marginTop: '10px',
                  float: 'right',
                  marginLeft: '10px',
                  width: '90px',
                  height: '35px',
                  // backgroundColor: '#FCBE13',
                  color: 'white',
                  fontSize: '14px',
                }}
              >
                <SearchOutlinedIcon
                  fontSize="medium"
                  style={{ color: 'white', paddingBottom: '1px' }}
                ></SearchOutlinedIcon>
                Search
              </Button>
              <Button
              className='Button'
                onClick={onFilterClear}
                variant="gradient"
                style={{
                  marginTop: '10px',
                  marginLeft: 'auto',
                  float: 'right',
                  width: '90px',
                  height: '35px',
                  // backgroundColor: '#FCBE13',
                  color: 'white',
                  fontSize: '14px',
                }}
              >
                <CleaningServicesOutlinedIcon
                  fontSize="medium"
                  style={{ color: 'white', paddingRight: '5px' }}
                ></CleaningServicesOutlinedIcon>
                Clear
              </Button>
            </Grid>
          </Grid>
        </Grid>


              {/* /////////// */}
              {/* <Grid container>
              <Grid item xs={1.5} style={{ width: "300px" }}>
                  <Typography style={{marginTop:'4px',fontWeight:"bold", marginTop:'8px'}}>Patient Search</Typography>
                </Grid>
              <Grid item xs={6}>
                <Box mt={"5px"} mb={0}>
                  <input
                 
                    placeholder="Search here..."
                    onChange={(evt) => {
                      setRows(orignalRows);
                      let keyword = evt.target.value;
                      if (keyword == "") {
                        return;
                      }
                      keyword = keyword.toLowerCase();
                      let filtered = rows.filter((entry) =>
                        Object.values(entry).some(
                          (val) =>
                            typeof val === "string" &&
                            val != null &&
                            val != "" &&
                            val != "null" &&
                            val.toLowerCase().includes(keyword)
                        )
                      );
                      setRows(filtered);
                    }}
                    spacing={0}
                    item
                    xs={12}
                    sm={12}
                    xl={12}
                    style={{
                      width: "100%",
                      height:'30px',
                      marginBottom: "0px",
                      border: "1px solid rgba(12, 152, 236, 0.637)",
                      borderRadius: "20px",
                      marginTop: "0px",
                      paddingBottom: "0px",
                      marginLeft: "0px",
                      padding: "12px",
                      paddingLeft: "18px",
                      paddingRight: "5px",
                      marginRight: "5px",
                      // paddingLeft: '15px',
                      backgroundColor: "white",
                    }}
                  />
                </Box>
              </Grid>
              </Grid> */}
            
              <Box mb={3} marginTop={2}>
          {isLoading ? (
            <div style={{ margin:'5rem', textAlign:'center' }}>
               <CircularProgress /> 
            </div>
          ) : (
            <div style={{ height: 400, width: "100%"}}  >
              {rows === null || rows.length === 0 ? (
            <Typography>{NoRowsLabel || GETTING_ROWS_LABEL}</Typography>
          ) : (
           
              <DataGrid
     
              rows={rows}
              rowHeight={28}
              columns={columns}
          classes={gridClasses}
              sx={ gridRowStyle }
           
              pagination
              autoPageSize
              // className="custom-header"
              
            />
              )}
            </div>
             )}
          </Box>
            </Container>
    </div>

  );
};

export default Patient;
