// Imports

import React, { useState, useEffect, useRef } from "react";
import { Box, Button, Grid, Container, Modal, Typography, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Backdrop } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete'
import Swal from 'sweetalert2';
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import CircularProgress from "@mui/material/CircularProgress";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { CancelOutlined } from "@mui/icons-material";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";
import JoditEditor from 'jodit-react';
import * as Yup from 'yup'

import { useNavigate } from "react-router-dom";
import Moment from 'moment'
import { useAlert } from "react-alert";
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined'
import ButtonGroup from '@mui/material/ButtonGroup'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { yupResolver } from "@hookform/resolvers/yup";

const PatientNote = (props) => {
  const editor = useRef(null);
  const [content, setContent] = useState('');

  const patientId = props.PatientId

  const [submitting, setSubmitting] = useState(false);

  // API Authanticatiion
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const navigate = useNavigate();
  const [rows, setRows] = useState("");
  const [Noteidrows, setNoteidrows] = useState("");
  const [isLoading, setLoading] = useState(false);
  // const [dos , setDos] = React.useState(Moment().format('YYYY-MM-DD'))
  const [patnoteid, setPatNoteId] = useState()
  const [noteetext, setnoteText] = useState("")
  const [noteetext1, setnoteText1] = useState("")
  const [patId, setPatId] = useState('');
  const [visidate, setVisitDate] = React.useState(Moment().format('YYYY-MM-DD'));
  const [refresh, setRefresh] = useState(false)



  const PatientId = props.PatientId;

  const [editorVisible, setEditorVisible] = useState(false);
  const [editorContent, setEditorContent] = useState("");

  const toggleEditor = () => {
    reset()
    setEditorVisible((prevVisible) => !prevVisible);
  };

  const closeeditor = () => {
    setEditorVisible(false);
  };
  const handleEditorChange = (content) => {
    // Remove HTML tags using a regular expression
    const sanitizedContent = content.replace(/<\/?[^>]+(>|$)/g, "");
  
    console.log("Content entered:", sanitizedContent);
  
    setValue('noteText', sanitizedContent);
    setnoteText(sanitizedContent);
  };
  
  const [openAlert, setOpenAlert] = useState(false);
  const [cellid, setcellid] = useState('');
  const handleClickOpen = () => {
 
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);

  };

  //Modal
  const handleDeleteById = () => {
    const postData = {
             
      patientNoteId: cellid,
      deleted: true,
    };
   
  axios
  .post(
      `${process.env.REACT_APP_API_URL}/PatientNote/AddPatientNote`,
      postData,
      {
          headers,
      }
  )
  .then((response) => {
 
      setRefresh(!refresh);
      setOpenAlert(false);
  })
  .catch((error) => {
      console.error(
          "There was an error!",
          error
      );
  });
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const validationSchema = Yup.object({
    noteText: Yup.string().required("Note is required"),

  });
  const style = {
    position: "absolute",
    top: "50%",
    left: "55%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const checkstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%" ,
    height: 380,
    bgcolor: "background.paper",
    border: "2px solid #377562",
    boxShadow: 24,
    padding:'5px',
    // zIndex: 10,
    scroll:'auto'

  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/PatientNote/getPatientNote?PatientId=${PatientId}`,
        {
          headers,
        }
      )
      .then((response) => {
        fields.forEach(field => {
          setValue(field, response[field])
        })
        setRows(response)
        setLoading(false)


      })

      .catch((error) => {
        console.error("There was an error!", error);
        setLoading(false);
      });
  }, [refresh,patId]);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      headerClassName: "super-app-theme--header",
      cellClassName: "gridCell",
      sortable: false,
      flex: 0.3,
      renderCell: (cellValues) => (
        <Button
          size="small"
          variant="text"
          color="info"
          style={{ float: 'left', marginLeft: '-1.4rem' }}
          onClick={(event) => {
            setEditorVisible(true);
            var patientNoteId = cellValues.row.id;
            axios
              .get(
                `${process.env.REACT_APP_API_URL}/PatientNote/getPatientNoteById?patientnoteid=${patientNoteId}`,
                {
                  headers,
                }
              )
              .then((response) => {
                if (response.length > 0) {
                  var res = response[0];
                  setNoteidrows(res);
                  setnoteText1(res.noteText);
                  setPatNoteId(res.patientNoteId);
                  var visiidate = Moment(res.visitDate).format("YYYY-MM-DD");
                  setVisitDate(visiidate);
                  console.log("vididididi", visidate);
                }
                setLoading(false);
              })
              .catch((error) => {
                console.error("There was an error!", error);
                setLoading(false);
              });
          }}
        >{`${cellValues.row.id}`}</Button>
      ),
    },
    {
      field: "noteText",
      headerName: "Patient Notes",
      headerClassName: "super-app-theme--header",
      cellClassName: "gridCell",
      sortable: false,
      flex: 0.5,
    },
    {
      field: "signed",
      headerClassName: "super-app-theme--header",
      headerName: "Signed",
      minWidth: 100,
      hide: false,
      renderCell: (params) =>
        params.row.questionnaire ? (
          <CheckIcon style={{ color: "green" }} />
        ) : (
          <ClearIcon style={{ color: "red" }} />
        ),
    },
    {
      field: "signedBy",
      headerName: "Signed By",
      headerClassName: "super-app-theme--header",
      cellClassName: "gridCell",
      sortable: false,
      flex: 0.3,
    },
    {
      field: "signedDate",
      headerName: "Signed Date",
      headerClassName: "super-app-theme--header",
      cellClassName: "gridCell",
      sortable: false,
      flex: 0.3,
    },
    {
      field: "visitDate",
      headerName: "Visit Date",
      headerClassName: "super-app-theme--header",
      cellClassName: "gridCell",
      sortable: false,
      flex: 0.4,
    },
    {
      field: 'deleted',
      headerName: '',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      minWidth: 0.5,
      renderCell: cell => (<>
        {props.Authorization !=='Authorization'?(
            <DeleteIcon
            style={{
              marginRight: '5px',
              float: 'right',
              marginTop: '5px',
              color: 'red',
              cursor: 'pointer',
              zIndex: 250000,
            }}
            onClick={() => {
              Swal.fire({
                title: 'Do you want to delete the record?',
                text: 'This action cannot be undone.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                target: document.querySelector('#hello-world'),
              }).then((result) => {
                if (result.isConfirmed) {
                  const postData = {
             
                    patientNoteId: cell.row.id,
                    deleted: true,
                  };
  
                  axios
                    .post(`${process.env.REACT_APP_API_URL}/PatientNote/AddPatientNote`, postData, {
                      headers,
                    })
                    .then(response => {
                      setRefresh(!refresh);
                      Swal.fire(
                        {
                          title: 'Deleted',
                          text: 'Your file has been deleted.',
                          icon: 'success',
                          target: document.querySelector('#hello-world'),
                        }
  
                      );
                      // Handle any other actions after successful delete
                    })
                    .catch(error => {
                      console.error('There was an error!', error);
                    });
                }
              });
            }}
          />
        ):
        // 1111
       ( 
       <>
        <Button onClick={() => {handleClickOpen(cell)
        setcellid(cell.row.id)
        }}>
       <DeleteIcon
       
         color="black"
         style={{
          marginRight: '5px',
          float: 'right',
          marginTop: '5px',
          color: 'red',
          cursor: 'pointer',
          zIndex: 250000,
        }}
       />
     </Button>
     <Dialog
       open={openAlert}
       onClose={handleCloseAlert}
       aria-labelledby="responsive-dialog-title"
  
         BackdropComponent={Backdrop}
         BackdropProps={{
           invisible: true,
         }}
     >
       <DialogContent>
         <DialogContentText style={{ color: 'black' }}>
           Do you want to delete the record?
         </DialogContentText>
       </DialogContent>
       <DialogActions>
       <Button onClick={handleDeleteById} style={{ background: 'red',color:'white' }} autoFocus>
           Yes
         </Button>
         <Button autoFocus onClick={handleCloseAlert} style={{ color: 'white',background:'#3085d6' }}>
           No
         </Button>
        
       </DialogActions>
     </Dialog></>)}
      </>
      ),
    }
  ];


  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 2,
    background: 'drak',
    fontSize: '13.4px !important',
    color: 'drak !important',
    borderColor: '#326C1D',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#377562',
      borderRadius: 0.1,
      color: 'white',
      fontSize: '16px !important',
      
    }, 

  }
  const fields = [
    "patientId",
    "visitDate",
    "noteText",

  ];

  const defValues = {
    // id: 0,
    patientId: patientId,
    visitDate: '',
    noteText: '',
  };

  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues,
  });
  const alert = useAlert();
  const onSubmit = (data) => {
    // setSubmitting(true);
    const postData = data;
    data.patientId = patientId;
    data.visitDate = visidate;
    data.patientNoteId = patnoteid;
    data.noteText = noteetext;

    axios
    .post(
      `${process.env.REACT_APP_API_URL}/PatientNote/AddPatientNote`,
      postData, { headers, }
    )
    .then((response) => {
      setEditorVisible(false)

      if (response.patientId) {
        setSubmitting(false)
        closeeditor()
        setRefresh(!refresh);
        // applyRefresh()
        Swal.fire({
          icon: 'success',
          timer: 1000,
          title: 'Note saved successfully.',
          onClose: () => {
            setEditorVisible(false);
          }
        });
      }
    })
    .catch((error) => {
      setSubmitting(false);
      Swal.fire({
        icon: 'error',
        title: `Error ${error.message}`,
        timer: 1000, 
      });
    });
};
  function applyRefresh() {
    setRefresh(!refresh)
    // setOpen(false)
  }
  

  
  return (
    <div>
      <Grid container  >
             <Grid item xs={12}>
             <Grid item spacing={1} xs={12} sm={12} md={12} lg={12}>
             <ButtonGroup aria-label='outlined primary button group'>
              <Button className="Button" variant="contained" onClick={toggleEditor}>
              <AddOutlinedIcon
                fontSize='small'
                style={{
                  color: 'white',
                  paddingRight: '4px',
                  paddingBottom: '2px'
                }}
              ></AddOutlinedIcon>
                Add New
              </Button>
              <Button
                className="Button"
                variant="contained"
                onClick={event => {
                  applyRefresh()
                }}
                style={{ marginLeft: '1rem' }}
              >
                 <CachedOutlinedIcon
                fontSize='small'
                style={{
                  color: 'white',
                  marginRight: '1px',
                  paddingRight: '0px',
                  paddingBottom: '1px'
                }}
              ></CachedOutlinedIcon>
                Refresh
              </Button>
            </ButtonGroup>
             </Grid>
             <>
             <Box mb={3} marginTop={2}>
          {isLoading ? (
            <div style={{ margin:'5rem', textAlign:'center' }}>
               <CircularProgress /> 
            </div>
          ) : (
            <div style={{ height: 400, width: "100%" }}>
              {rows === null || rows.length === 0 ? (
            <Typography>No Record found</Typography>
          ) : (
                <DataGrid
                  rows={rows}
                  rowHeight={28}
                  columns={columns}
                  headerHeight={28}
                  sx={gridRowStyle} 
                  pagination
                  autoPageSize
                />
              )}
            </div>
             )}
          </Box>
             </>
             </Grid>
           
           
          </Grid>
         
         
         
          {editorVisible && (
        <Box sx={checkstyle}>
          <Grid container spacing={0}>
          <Grid item xs={12} style={{ fontWeight: 500,backgroundColor: '#377562', }}>
                      <Typography style={{ fontWeight: 'bold', fontSize: '16px', color: 'white', }}>
                      Add Notes
                      </Typography>
            <CancelOutlined
              onClick={closeeditor}
              fontSize="medium"
              style={{
                color: "red",
                float: "right",
                marginTop: "-22px",
                // marginRight: "-30px",
                // cursor: 'pointer'
              }}
            ></CancelOutlined>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography style={{ fontWeight: "bold", color: "black", }}>
              DOS
            </Typography>
            <Controller
              name="visitDate"
              {...register("visitDate")}
              control={control}
              render={({ field }) => (
                <TextField
                  // variant="standard"
                  style={{
                    marginBottom: "2px",
                    width: "100%",
                    
                  }}
                  size="small"
                  fullWidth
                  {...field}
                  name="visitDate"
                  type="date"
                  value={visidate}
                  onChange={e => {
                    setValue('visitDate', e.target.value)
                    setVisitDate(e.target.value)
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div style={{ width: "100%", height: 200, overflow: 'auto' }}>
              <JoditEditor
                ref={editor}
                value={noteetext1}
                onChange={handleEditorChange}
                error={errors.noteText ?'Note Is Empty':''}
                helperText={errors.noteText ? 'Note Is Empty': 'Note Is Empty'}
              />
            </div>
           
          </Grid>
              {noteetext.length == 0 ? <p style={{ color: 'red' }}>Note Is Empty</p> : ''}

          <Grid item xs={12}>
            <Button
              className="Button"
              onClick={handleSubmit(onSubmit)}
              variant="gradient"
              disabled={submitting}
              style={{
                // marginTop: "100px",
                marginBottom: "10px",
                marginTop: "5px",
                marginRight: "20px",
                float: "right",
                // marginLeft: "890px",
                width: "80px",
                height: "35px",
                // backgroundColor: "#A574FD",
                color: "white",
                fontSize: "14px",
              }}
            >
              Save
            </Button>
          </Grid>

          </Grid>
          
        </Box>
      )}
     


      <Container>
        {/* <Box mb={3} margin={3}>
          {isLoading ? (
            <div style={{ margin: "5rem", textAlign: "center" }}>
              <CircularProgress />
            </div>
          ) : (
            <> */}
              {/* <div style={{ height: 400, width: "100%" }}>
                {rows === null || rows.length === 0 ? (
                  "No Record Found"
                ) : (
                  <DataGrid
                    rows={rows}
                    rowHeight={28}
                    columns={columns}
                    headerHeight={28}
                    sx={gridRowStyle}
                    pagination
                    autoPageSize
                  />
                )}
              </div> */}
              {/* 
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <CancelOutlined
                    onClick={handleClose}
                    style={{
                      float: "right",
                      color: "red",
                      marginTop: "-30px",
                      cursor: "pointer",
                      marginRight: "-20px",
                    }}
                  ></CancelOutlined>
                  <div style={{ height: 400, width: "100%" }}>
                    {Noteidrows === null || Noteidrows.length === 0 ? (
                      "No Record Found"
                    ) : (
                      <DataGrid
                        rows={Noteidrows}
                        rowHeight={28}
                        columns={PatientNotecolumns}
                        headerHeight={28}
                        PatientSize={5}
                        rowsPerPatientOptions={[5]}
                        sx={gridRowStyle}
                      />
                    )}
                  </div>
                </Box>
              </Modal> */}
            {/* </>
          )} */}
        {/* </Box> */}
      </Container>
    </div>
  );
};

export default PatientNote;
