// ** React Imports
import { useState, useEffect } from 'react'
import axios from 'axios'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import React from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import Button from '@mui/material/Button'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import { Grid, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import Modal from '@mui/material/Modal';

import TabPanel from "@mui/lab/TabPanel";
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
const Appointments = (props) => {
  document.title = "Diagnosis | LuxBilling";

  const getRowSpacing = React.useCallback(params => {
    return {
      top: params.isFirstVisible ? 0 : 0.5,
      bottom: params.isLastVisible ? 0 : 0.8
    }
  }, [])
  const [isLoading, setLoading] = useState(false)
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    // resolver: yupResolver(validationSchema),
    // defaultValues: defValues,
  });

  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 2,
    background: 'drak',
    fontSize: '13.4px !important',
    color: 'drak !important',
    borderColor: '#326C1D',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#377562',
      borderRadius: 0.1,
      color: 'white',
      fontSize: '16px !important',

    },

  }
  const gridClasses = {
    columnHeader: 'MuiDataGrid-columnHeaders',
  };
  const [value, setvalue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setvalue(newValue);
  };
  const [refresh, setRefresh] = useState(false)
  const [Data, SetData] = useState([])
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.1
    },

    {
      field: 'appointmentDate',
      headerName: 'Appointment Date',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
      valueGetter: (params) => {
        const appointmentDate = params.row.appointmentDate;
        return appointmentDate ? moment(appointmentDate).format('MM-DD-YYYY') : '';
      },
      renderCell: (cellValues) => (
        <Button
          style={{ color: 'rgba(58, 53, 65, 0.87)', display: 'flex', justifyContent: 'flex-start' }}
          size="small"
          variant="text"
          color="info"
          onClick={() => {
            setOpen(true);
            let rowid = cellValues.row.id;
            axios
              .get(`${process.env.REACT_APP_API_URL}/Appointment/getAppointmentById?id=${rowid}`, { headers })
              .then((response) => {
                SetData(response)

              })
              .catch((error) => {
                console.error('Error fetching data:', error);
              });
          }}
        > {cellValues.row.appointmentDate ? moment(cellValues.row.appointmentDate).format('MM-DD-YYYY') : ''}</Button>
      ),
    },
    {
      field: 'startTime',
      headerName: 'Start Time',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.2,
      valueGetter: (params) => params.value ? moment(params.value).format('HH:mm') : '',
    },
    {
      field: 'endTime',
      headerName: 'EndTime',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.2,
      valueGetter: (params) => params.value ? moment(params.value).format('HH:mm') : '', 
    },
    {
      field: 'duration',
      headerName: 'Duration',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.2
    },
    {
      field: 'providerName',
      headerName: 'Provider Name',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.3
    },
    {
      field: 'appointmentReason',
      headerName: 'Appointment Reason',
      cellClassName: 'gridCell',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      flex: 0.3
    },
    {
      field: 'inactive',
      headerName: 'Delete',
      sortable: true,
      headerClassName: 'super-app-theme--header',
      minWidth: 50,
      renderCell: cell => (
        <DeleteIcon
          style={{
            marginRight: '5px',
            float: 'right',
            marginTop: '5px',
            color: 'red',
            cursor: 'pointer'
          }}
          onClick={cellValues => {
            // console.log('Cell: ', cell)
            confirmAlert({
              message: 'Do you want to delete the record.',
              closeOnEscape: true,
              closeOnClickOutside: true,
              overlayClassName: 'overlay-custom-class-name',
              buttons: [
                {
                  label: 'Yes',
                  onClick: () => {
                    const postData = {
                      id: cell.row.id,
                      deleted: true,
                    }

                    axios
                      .post(`${process.env.REACT_APP_API_URL}/Appointment/AddAppointment`, postData, {
                        headers
                      })
                      .then(response => {
                        setRefresh(!refresh)
                        // setOpenNote(false)
                        // console.log('response.data: ', response.data)
                      })
                      .catch(error => {
                        console.error('There was an error!', error)
                      })
                  }
                },
                {
                  label: 'No'
                }
              ]
            })
          }}
        ></DeleteIcon>
      )
    }
  ]
  const patientId = props.patientId
  const [rows, setRows] = useState(null)
  useEffect(() => {
    setLoading(true)
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/Appointment/getAppointments`, { headers })
      .then(response => {
        setLoading(false)
        const filteredRows = response.filter(row => row.patientId == patientId);
        setRows(filteredRows);
        // setRows(response)
      })
      .catch(error => { })

  }, [refresh])


  const [Open, setOpen] = useState(false)
  const HanldeOpenModal = () => {
    setOpen(true)
  }
  const HandleCloseModal = () => {
    setOpen(false)
  }
  const style = {
    position: 'absolute',
    top: '54%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.5)',
    padding: '15px',
    borderRadius: '8px',
    maxHeight: '89vh',
    // overflow: 'auto',

  };
  const formattedAppointmentDate = moment(Data.appointmentDate).format('DD-MM-YYYY');
  const formattedCreatedDate = moment(Data.createdDate).format('DD-MM-YYYY')
  return (
    <div >


      <Modal
        open={Open}
        onClose={HandleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* <form onSubmit={handleSubmit(onSubmit)}> */}
          <Grid
            container xs={12}
            style={{
              background: '#377562',
              position: 'sticky',
              marginBottom: '14px',
              padding: '8px',
              borderRadius: '8px',
              boxShadow: '0 4px 8px rgba(54, 118, 196, 0.5)',
              display: 'flex'
            }}
          >
            <Grid item xs={11}>
              <Typography style={{
                fontSize: '17px',
                fontWeight: 'bold',
                marginLeft: '5px',
                color: '#fff'
              }}>Appointments </Typography>
            </Grid>
            <Grid item xs={1}>
              <CancelOutlinedIcon
                onClick={HandleCloseModal}
                fontSize="medium"
                color="red"
                style={{
                  color: 'red',
                  cursor: 'pointer',
                  float: 'right',
                }}
              ></CancelOutlinedIcon>
            </Grid>
          </Grid>

          <Grid container style={{
            backgroundColor: '#F0F6F8',
            borderRadius: '15px',
            borderColor: '#377562',
            border: '1px solid #377562',

          }} >
            <Grid item xs={12} style={{ fontWeight: 500 }}>
              <Typography style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '20px', marginTop: '2px' }}>
                Patient Name : {Data.patientName}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ fontWeight: 500 }}>
              <Typography style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '20px', marginTop: '2px' }}>
                Appointment Date : {formattedCreatedDate}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ fontWeight: 500 }}>
              <Typography style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '20px', marginTop: '2px' }}>
                Appointment Reason : {Data.appointmentReason}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ fontWeight: 500 }}>
              <Typography style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '20px', marginTop: '2px' }}>
                Duration : {Data.duration}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ fontWeight: 500 }}>
              <Typography style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '20px', marginTop: '2px' }}>
                Created By : {Data.createdBy}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ fontWeight: 500 }}>
              <Typography style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '20px', marginTop: '2px' }}>
                Created Date :{formattedCreatedDate}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ fontWeight: 500 }}>
              <Typography style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '20px', marginTop: '2px' }}>
                Provider Name : {Data.providerName}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Container >

        {/* <Button className="Button" onClick={HanldeOpenModal} variant='contained'>
                <AddOutlinedIcon
                fontSize='small'
                style={{
                  color: 'white',
                  paddingRight: '4px',
                  paddingBottom: '2px'
                }}
              ></AddOutlinedIcon>
                  Add New
                </Button> */}


        <Box mb={3} marginTop={2}>
          {isLoading ? (
            <div style={{ margin: '5rem', textAlign: 'center' }}>
              <CircularProgress />
            </div>
          ) : (
            <div style={{ height: 400, width: "100%" }}>
              {rows === null || rows.length === 0 ? (
                <Typography>No Record found</Typography>
              ) : (
                <DataGrid
                  rows={rows}
                  classes={gridClasses}
                  rowHeight={20}
                  columns={columns}
                  getRowSpacing={getRowSpacing}
                  //checkboxSelection
                  fullWidth
                  pagination
                  autoPageSize
                  sx={gridRowStyle}
                />
              )}
            </div>
          )}
        </Box>

      </Container>
    </div>


  )
}

export default Appointments
