
import React, { useCallback, useMemo, useState, useEffect } from "react";
import Chart from 'react-apexcharts';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Box, Button, Container, Stack, SvgIcon, Typography, Grid, Snackbar, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Swal from 'sweetalert2';

// import { PieChart } from '@mui/x-charts/PieChart';
import CircularProgress from '@mui/material/CircularProgress'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import { DataGrid } from '@mui/x-data-grid'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import Paper from '@mui/material/Paper'
import ReactSelect from 'react-select'
import { useAlert } from 'react-alert'
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined'
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import Tooltip from '@mui/material/Tooltip';
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

// import { PieChart } from '@mui/x-charts/PieChart';
// const openHome = (event, id) => {
//   navigate("/Home", { state: { patientId: id } });
// };
import Modal from "@mui/material/Modal";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Card from '@mui/material/Card';

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Checkbox from '@mui/material/Checkbox';
import Headroom from "react-headroom";
import AuthorizationRegistration from "../AuthorizationRegistration";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import VOBPAGE from "../DashForms/Vobpage";
// import VOBPAGE1 from "../DashForms/Vobpage1";
import OSAPAGE from "../DashForms/Osapage";
import Letter from "../DashForms/Letter";
import Disclaimer from "../DashForms/Disclaimer";
import Prior_auth from '../Prior_auth'
import PrintIcon from '@mui/icons-material/Print';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import Avatar from '@mui/material/Avatar';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import VOBPAGE1 from "../Vobpage1";
import ClaimRegistration from "../ClaimRegistration";

function Dashboard(props) {
  document.title = "Dashboard | LuxBilling";
console.log(props,'props ki')
  // const auth = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImN0eSI6IkpXVCJ9.eyJqdGkiOiIwZmRiNWIxMy0wYzExLTQ4ZTQtOTdmMy1hZjhlOGNmOTJlMjgiLCJ2YWxpZCI6IjEiLCJ1c2VyRW1haWwiOiJhbGlAZ21haWwuY29tIiwicHJhY3RpY2VJZCI6IjEiLCJleHBpcnlEYXRlIjoiNy8zLzIwMjgiLCJicm93c2VyIjoiR29vZ2xlIENocm9tZSIsImFwcGxpY2F0aW9uIjoiTHV4QmlsbGluZyIsIklzQWRtaW4iOiJGYWxzZSIsIlByb3ZpZGVySWQiOiIwIiwiZXhwIjoxODQ2MDgwNTE2LCJpc3MiOiJodHRwOi8vd3d3Lkx1eEJpbGxpbmcuY29tIiwiYXVkIjoiaHR0cDovL3d3dy5MdXhCaWxsaW5nLmNvbSJ9.2Uac6a-c-IPRJFzYSvjxpRych9icwhsebjvB2lgZaOs`;
  // const auth = localStorage.getItem("Token");
  const token = localStorage.getItem('Token')
  const userType = localStorage.getItem('User_Type')
  console.log('testtoken__+',userType)

  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const navigate = useNavigate();
  // const navigateToPage =()=>{
  //   navigate ('/Home')
  //   console.log(" Navigate to homes")
  // }
  const [approvedNotification, setApprovedNotification] = useState(null);
  const [claimNotification, setclaimNotification] = useState(null);
  const [claimNotification1, setclaimNotification1] = useState(null);
  const [claimNotificationpayment, setclaimNotificationpayment] = useState(null);

  const [authId, setauthId] = useState();
  const [claimId, setclaimId] = useState();

  const [patinetIdopenauth, setpatinetIdopenauth] = useState();
  const [patinetIdopenclaim, setpatinetIdopenclaim] = useState();

  const [patinetIdopenauth1, setpatinetIdopenauth1] = useState();
  const [patinetnameopenauth1, setpatinetnameopenauth1] = useState();
  const [patinetnameopenauth, setpatinetnameopenauth] = useState();
  const [patinetnameopenclaim, setpatinetnameopencalim] = useState();


  
  const [authId2, setauthId2] = useState(0);
  const [authIdcpt, setauthIdcpt] = useState();

  
  const [isLoading, setLoading] = useState(false)
  const [rows, setRows] = useState(null)
  const [rows1, setRows1] = useState(null)

  
  const [raws, setRaws] = useState(null)
  const [raws1, setRaws1] = useState(null)
  const [raws2, setRaws2] = useState(null)
  const [raws3, setRaws3] = useState(null)
  const [raws4, setRaws4] = useState(null)
  const [isChecked, setIsChecked] = useState(false)
  console.log(isChecked,'testre_+_+')
  const [raws5, setRaws5] = useState([])
  const [compeleterows, setcompeleterows] = useState([])
  console.log('testttttrows',compeleterows)
  const [panddingrows, setpanddingrows] = useState([])
  const [paymentrows, setpaymentrows] = useState([])

  const [fromDate, setfromDate] = React.useState('')
  const [toDate, settoDate] = useState('')
  const [Dashboardinfo, setDashboardinfo] = useState([])
  const [show, setshow] = useState('')
  const [data, setData] = useState([]);
  const [letterOfMedicalNecessity, setLetterOfMedicalNecessity] = useState([]);
  const [letterOfMedicalNecessity1, setLetterOfMedicalNecessity1] = useState('');

  console.log(letterOfMedicalNecessity1,'response.data: ++')
  console.log(letterOfMedicalNecessity,'response.data: ++1')


  const [questionnaire, setQuestionnaire] = useState([]);
  const [progressNotes, setProgressNotes] = useState([]);
  const [authRequest, setAuthRequest] = useState([]);
  const [authRequest1, setAuthRequest1] = useState('');
  const [approved, setApproved] = useState([]);
  const [approved1, setApproved1] = useState('');

  const [patientInfo, setPatientInfo] = useState([]);
  const [totalRecords, setTotalRecords] = useState([]);
  const [totalRecords1, setTotalRecords1] = useState([]);

  const [selectedLabel, setSelectedLabel] = useState(null);
  const [selectedLabel1, setSelectedLabel1] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);

  const [modalData, setModalData] = useState(null);
  const [columnID, setColumnID] = React.useState("");
  const [columnIDsubluxbilling, setColumnIDsubluxbilling] = React.useState("");
  const [patientNamee, setPatientNamee] = React.useState("");
 const [compeletclaims, setcompeletclaims] = useState([]);
  const [penndingclaims, setpenndingclaims] = useState([]);
  const [paidclaims, setpaidclaims] = useState([]);
  const [AutpattientID1, setAutpattientID1] = React.useState("");
  const [formDid, setColumnIDs] = React.useState("");

  console.log(AutpattientID1,'AutpattientID1')
  const [AutpattientID, setAutpattientID] = React.useState("");
  const testpateint = props.PatientName;
  const [patientName, setPatientName] = React.useState(testpateint);
  console.log(patientName,'patientNamee')
  const [approvelux, setapprovelux] = React.useState(false);
  const [Markaporoved, setMarkaporoved] = React.useState(false);
  const [Markaporovedopenauth, setMarkaporovedopenauth] = React.useState(false);
  console.log(Markaporovedopenauth,'Markaporovedopenauth')
  useEffect(() => {
    // Set the default value of fromDate to the 1st date of the current month
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const formattedFromDate = formatDate(firstDayOfMonth);
    setfromDate(formattedFromDate);

    // Set the default value of toDate to the current date
    const formattedToDate = formatDate(today);
    settoDate(formattedToDate);
  }, []); // Empty dependency array ensures this effect runs only once on mount

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const handleapprovelux= () => {
    setapprovelux(true)
  };
  
  const handlecloseapprovelux = () => {
    setColumnID('')
    setAutpattientID1('')
     setPatientNamee('')
     setapprovelux(false)
  };
  useEffect(() => {
    if(AutpattientID1>0 )
    {
      handleapprovelux()
    }
  },[AutpattientID1]);
  const labelData = [
    // { label: `Total Records (${totalRecords})`, color: '#00e69d' },
    { label: `Submitted to LuxBilling (${authRequest1})`, color: '#ff2c52' },
    { label: `Approved (${approved1})`, color: '#7a5ccf' },
    { label: `Letter Of Medical Necessity (${letterOfMedicalNecessity})`, color: '#0092fb' },
    { label: `Questionnaire (${questionnaire})`, color: '#00e69d' },
    // { label: `Progress Notes (${progressNotes})`, color: '#ffac00' },
    { label: `Patient Info (${patientInfo})`, color: '#0092fb' },
    

  ];
  const labelData1 = [
    // { label: `Total Records (${totalRecords})`, color: '#00e69d' },
    { label: `Pending Claims (${penndingclaims})`, color: '#ff2c52' },
    { label: `Completed Claims (${compeletclaims})`, color: '#00e69d' },
    { label: `Paid Claims (${paidclaims})`, color: '#0092fb' },
   
    

  ];

  const combinedData = [
    ...letterOfMedicalNecessity,
    ...questionnaire,
    ...progressNotes,
    ...authRequest,
    ...approved,
    ...patientInfo,
    ...totalRecords,
  ];
  const checkstyleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    height: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    // overflow: "auto",
    overflows: "hide",
    // zIndex: "10",
  };
  const [opens, setOpens] = React.useState(false);
  const handleCloseVOB = () => {
    setOpens(false)
  };

  const handleOpens = () => {
    setOpens(true)};
  
  const calumns = [
    {
      field: 'authId',
      headerName: 'Auth ID',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 1.5,
      renderCell: (cellValues) => (
        <Button
        style={{ float: 'left', marginLeft: '-15px' }}
          size="small"
          variant="text"
          color="info"
          onClick={(event) => {
            setColumnID(cellValues.row.authId)
            setAutpattientID(cellValues.row.patientId)
            const fullName = cellValues.row.lastName + ", " + cellValues.row.firstName;
            setPatientName(fullName);
            setOpenModal(true)
          }}
        >{`${cellValues.row.authId}`}</Button>
      ),
    },


    {
      field: 'firstName',
      headerName: 'First Name',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'gridCell',
      sortable: false,
      flex: 3,
},
    {
      field: 'lastName',
      headerName: 'Last Name',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'gridCell',
      sortable: false,
      flex: 3,
    },
    {
      field: 'dOB',
      headerName: 'DOB',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 3,
    },
    {
      field: 'gender',
      headerName: 'Gender',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 2
    },
    {
      field: 'practiceName',
      headerName: 'Practice Name',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 2.7
    },
    {
      field: 'city',
      headerName: 'City',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 3
    },
   {
      field: 'state',
      headerName: 'State',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 3
    },
   {
      field: 'address',
      headerName: 'Address',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 3
    },
   
  ]

  const calumns1 = [

    {
      field: 'authId',
      headerName: 'Auth ID',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 1.5,
      renderCell: (cellValues) => (
        <Button
        style={{ float: 'left', marginLeft: '-15px' }}
          size="small"
          variant="text"
          color="info"
          onClick={(event) => {
            setauthId(cellValues.row.authId)
            setAutpattientID(cellValues.row.patientId)
            const setPatientNamee = cellValues.row.lastName + ", " + cellValues.row.firstName;
            setPatientName(setPatientNamee);
            setOpenModal(true)
          }}
        >{`${cellValues.row.authId}`}</Button>
      ),
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'gridCell',
      sortable: false,
      flex: 3,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'gridCell',
      sortable: false,
      flex: 3,
    },
    {
      field: 'dOB',
      headerName: 'DOB',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 3,
    },
    {
      field: 'gender',
      headerName: 'Gender',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 2
    },
    {
      field: 'city',
      headerName: 'City',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 3
    },
   {
      field: 'state',
      headerName: 'State',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 3
    },

    {
      field: 'address',
      headerName: 'Address',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 3
    },


  ]
  const calumns2 = [
    {
      field: 'authId',
      headerName: 'Auth ID',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 1.5,
      renderCell: (cellValues) => (
        <Button
        style={{ float: 'left', marginLeft: '-15px' }}
          size="small"
          variant="text"
          color="info"
          onClick={(event) => {
            setColumnID(cellValues.row.authId)
            setAutpattientID(cellValues.row.patientId)
            // const setPatientNamee = cellValues.row.lastName + ", " + cellValues.row.firstName;
            setPatientName(`${cellValues.row.lastName},${cellValues.row.firstName}`);
            // setOpenModal(true)
            setapprovedasbord(true)
           
          }}
        >{`${cellValues.row.authId}`}</Button>
      ),
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'gridCell',
      sortable: false,
      flex: 2,
},
    {
      field: 'lastName',
      headerName: 'Last Name',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'gridCell',
      sortable: false,
      flex: 2,
    },
    {
      field: 'dOB',
      headerName: 'DOB',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 1.5,
    },
    {
      field: 'gender',
      headerName: 'Gender',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 1.6
    },
    {
      field: 'practiceName',
      headerName: 'Practice Name',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 2.7
    },
    {
      field: 'city',
      headerName: 'City',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 2
    },
   {
      field: 'state',
      headerName: 'State',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 2
    },

    {
      field: 'address',
      headerName: 'Address',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 2
    },
    {
      field: 'dateApproval',
      headerName: 'Date Approval',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 2
    },
    {
      field: 'dateSubmission',
      headerName: 'Date Sub',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 2
    },



  ]
  const calumns3 = [
    {
      field: 'authId',
      headerName: 'Auth ID',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 1.5,
      renderCell: (cellValues) => (
        <Button
        style={{ float: 'left', marginLeft: '-15px' }}
          size="small"
          variant="text"
          color="info"
          onClick={(event) => {
            setColumnID(cellValues.row.authId)
            setAutpattientID(cellValues.row.patientId)
            const setPatientNamee = cellValues.row.lastName + ", " + cellValues.row.firstName;
            setPatientName(setPatientNamee);
            setOpenModal(true)
          }}
        >{`${cellValues.row.authId}`}</Button>
      ),
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'gridCell',
      sortable: false,
      flex: 3,
},
    {
      field: 'lastName',
      headerName: 'Last Name',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'gridCell',
      sortable: false,
      flex: 3,
    },
    {
      field: 'dOB',
      headerName: 'DOB',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 3,
    },
    {
      field: 'gender',
      headerName: 'Gender',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 2
    },
    {
      field: 'practiceName',
      headerName: 'Practice Name',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 2.7
    },
    {
      field: 'city',
      headerName: 'City',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 3
    },
   {
      field: 'state',
      headerName: 'State',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 3
    },

    {
      field: 'address',
      headerName: 'Address',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 3
    },
  
  ]
  const calumns4 = [
    {
      field: 'authId',
      headerName: 'Auth ID',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 1.5,
      renderCell: (cellValues) => (
        <Button
        style={{ float: 'left', marginLeft: '-15px' }}
          size="small"
          variant="text"
          color="info"
          onClick={(event) => {
            setColumnID(cellValues.row.authId)
            setAutpattientID(cellValues.row.patientId)
            const setPatientNamee = cellValues.row.lastName + ", " + cellValues.row.firstName;
            setPatientName(setPatientNamee);
            setOpenModal(true)
          }}
        >{`${cellValues.row.authId}`}</Button>
      ),
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'gridCell',
      sortable: false,
      flex: 3,
},
    {
      field: 'lastName',
      headerName: 'Last Name',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'gridCell',
      sortable: false,
      flex: 3,
    },
    {
      field: 'dOB',
      headerName: 'DOB',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 3,
    },
    {
      field: 'practiceName',
      headerName: 'Practice Name',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 2.7
    },
    {
      field: 'gender',
      headerName: 'Gender',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 2
    },
    {
      field: 'city',
      headerName: 'City',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 3
    },
   {
      field: 'state',
      headerName: 'State',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 3
    },

    {
      field: 'address',
      headerName: 'Address',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 3
    },

  ]
  const calumns5 = [
    {
      field: 'authId',
      headerName: 'Auth ID',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 1.5,
      renderCell: (cellValues) => (
        <Button
        style={{ float: 'left', marginLeft: '-15px' }}
          size="small"
          variant="text"
          color="info"
          onClick={(event) => {
            setColumnID(cellValues.row.authId)
            setAutpattientID(cellValues.row.patientId)
            const setPatientNamee = cellValues.row.lastName + ", " + cellValues.row.firstName;
            setPatientName(setPatientNamee);
            setOpenModal(true)
          }}
        >{`${cellValues.row.authId}`}</Button>
      ),
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'gridCell',
      sortable: false,
      flex: 2,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'gridCell',
      sortable: false,
      flex: 2,
    },
    {
      field: 'dOB',
      headerName: 'DOB',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 2,
    },
    {
      field: 'gender',
      headerName: 'Gender',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 2,
    },
    {
      field: 'city',
      headerName: 'City',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 2
    },
    {
      field: 'practiceName',
      headerName: 'Practice Name',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 2.9
    },
   {
      field: 'state',
      headerName: 'State',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 1.5
    },

    {
      field: 'address',
      headerName: 'Address',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 2
    },
    {
      field: 'dateSubmission',
      headerName: 'Date Submission',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 3
    },
    
    {
      field: "Approve",
      headerName: "Approve",
      sortable: true,
      headerClassName: "super-app-theme--header",
      flex: 2,
      renderCell: (cell) => {
        if (cell.row.approved) {
          // If approved is true, render a disabled button or some other content
          return (
            <TaskAltIcon
            onClick={(e) => {
              setColumnID(cell.row.authId)
              setColumnIDs(cell.row.id)

              setAutpattientID1(cell.row.patientId)
              setPatientNamee(`${cell.row.lastName},${cell.row.firstName}`)
              setMarkaporoved(cell.row.approved)
             console.log(cell.row,'patientName___')
              // handleapprove()
             }}
            style={{
              marginRight: "5px",
              float: "right",
              marginTop: "5px",
              color: "green",
              // cursor: "not-allowed",
            }}></TaskAltIcon>
          );
        } else {
          // If approved is false, render the button that can be clicked
          return (
          
            <TaskAltIcon
              style={{
                marginRight: "5px",
                float: "right",
                marginTop: "5px",
                color: "red",
                cursor: "pointer",
                zIndex: 3500000,
              }}
              onClick={(cellValues) => {
                setColumnIDsubluxbilling(cell.row.id)
              setColumnIDs(cell.row.id)

                        setAutpattientID1(cell.row.patientId)
                        setPatientNamee(cell.row.PatientName)
              setColumnID(cell.row.authId)

                        setMarkaporoved(cell.row.approved)
             console.log(cell.row,'patientName___')

              }}
            ></TaskAltIcon>
          );
        }
      },
    },
  ]

 
  const panddingcalumns = [
    // {
    //   field: 'authId',
    //   headerName: 'Auth ID',
    //   cellClassName: 'gridCell',
    //   sortable: false,
    //   headerClassName: 'super-app-theme--header',
    //   flex: 1.5,
    //   renderCell: (cellValues) => (
    //     <Button
    //     style={{ float: 'left', marginLeft: '-15px' }}
    //       size="small"
    //       variant="text"
    //       color="info"
    //       onClick={(event) => {
    //         setColumnID(cellValues.row.authId)
    //         setAutpattientID(cellValues.row.patientId)
    //         const setPatientNamee = cellValues.row.lastName + ", " + cellValues.row.firstName;
    //         setPatientName(setPatientNamee);
    //         setOpenModal(true)
    //       }}
    //     >{`${cellValues.row.authId}`}</Button>
    //   ),
    // },
    {
      field: 'id',
      headerName: 'Id',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'gridCell',
      sortable: false,
      flex: 0.5,
    },
    {
      field: 'patientName',
      headerName: 'Patient Name',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'gridCell',
      sortable: false,
      flex: 2,
    },
    {
      field: 'dos',
      headerName: 'DOS',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    
    
   
  ]
  const paymentcalumns = [
    // {
    //   field: 'authId',
    //   headerName: 'Auth ID',
    //   cellClassName: 'gridCell',
    //   sortable: false,
    //   headerClassName: 'super-app-theme--header',
    //   flex: 1.5,
    //   renderCell: (cellValues) => (
    //     <Button
    //     style={{ float: 'left', marginLeft: '-15px' }}
    //       size="small"
    //       variant="text"
    //       color="info"
    //       onClick={(event) => {
    //         setColumnID(cellValues.row.authId)
    //         setAutpattientID(cellValues.row.patientId)
    //         const setPatientNamee = cellValues.row.lastName + ", " + cellValues.row.firstName;
    //         setPatientName(setPatientNamee);
    //         setOpenModal(true)
    //       }}
    //     >{`${cellValues.row.authId}`}</Button>
    //   ),
    // },
    {
      field: 'id',
      headerName: 'Id',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'gridCell',
      sortable: false,
      flex: 0.5,
    },
    {
      field: 'patientName',
      headerName: 'Patient Name',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'gridCell',
      sortable: false,
      flex: 2,
    },
    {
      field: 'dos',
      headerName: 'DOS',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    
    
   
  ]
  const compeletedcalumns = [
    // {
    //   field: 'authId',
    //   headerName: 'Auth ID',
    //   cellClassName: 'gridCell',
    //   sortable: false,
    //   headerClassName: 'super-app-theme--header',
    //   flex: 1.5,
    //   renderCell: (cellValues) => (
    //     <Button
    //     style={{ float: 'left', marginLeft: '-15px' }}
    //       size="small"
    //       variant="text"
    //       color="info"
    //       onClick={(event) => {
    //         setColumnID(cellValues.row.authId)
    //         setAutpattientID(cellValues.row.patientId)
    //         const setPatientNamee = cellValues.row.lastName + ", " + cellValues.row.firstName;
    //         setPatientName(setPatientNamee);
    //         setOpenModal(true)
    //       }}
    //     >{`${cellValues.row.authId}`}</Button>
    //   ),
    // },
    {
      field: 'id',
      headerName: 'Id',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'gridCell',
      sortable: false,
      flex: 0.5,
    },
    {
      field: 'patientName',
      headerName: 'Patient Name',
      headerClassName: 'super-app-theme--header',
      cellClassName: 'gridCell',
      sortable: false,
      flex: 2,
    },
    {
      field: 'dos',
      headerName: 'DOS',
      cellClassName: 'gridCell',
      sortable: false,
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    
    
   
  ]
  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    // resolver: yupResolver(validationSchema),
    // defaultValues: defValues
  })
  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 2,
    background: 'drak',
    fontSize: '13.4px !important',
    color: 'drak !important',
    borderColor: '#326C1D',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#377562',
      borderRadius: 0.1,
      color: 'white',
      fontSize: '16px !important',

    },

  }
  const gridClasses = {
    columnHeader: 'MuiDataGrid-columnHeaders',
  };
  useEffect(() => {
  if(isChecked){
    onFilterSearch()
  }

  },[isChecked])
const [HideData, setHideData] =useState(false)

const [isAllPractics, setisAllPractics] = useState(false)
console.log(isAllPractics,'testre++++')
const AllPractices = event => {
  setHideData(true) 
  setLoading(true);
  setRows(null);
    axios.get(`${process.env.REACT_APP_API_URL}/Dashboard/PatientAutherizationDashboard?showAllPractices=${isAllPractics}&Isall=${isChecked}`, { headers })
      .then(response => {
        setRows(response)
        setDashboardinfo(response)
        setLoading(false)
        piechart(response)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
      axios.get(`${process.env.REACT_APP_API_URL}/Dashboard/ClaimsDashboard?showAllPractices=${isAllPractics}&Isall=${isChecked}`, { headers })
      .then(response => {
        setRows1(response)
        setLoading(false)
        piechart1(response)
      
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });

}
useEffect(() => {
  if(isAllPractics){
    AllPractices()
  }

  },[isAllPractics])
  const onFilterSearch = event => {
    if (!isChecked && (!fromDate || !toDate)) {
      console.log("No action needed: checkbox unchecked and no date range selected.");
      return; // Do nothing
    }
    setHideData(true) 
    setLoading(true);
    setRows(null);
    if (isChecked) {
      axios.get(`${process.env.REACT_APP_API_URL}/Dashboard/PatientAutherizationDashboard?showAllPractices=${isAllPractics}&Isall=${isChecked}`, { headers })
        .then(response => {
          setRows(response)
          setDashboardinfo(response)
          setLoading(false)
          piechart(response)
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
        axios.get(`${process.env.REACT_APP_API_URL}/Dashboard/ClaimsDashboard?showAllPractices=${isAllPractics}&Isall=${isChecked}`, { headers })
        .then(response => {
          setRows1(response)
          setLoading(false)
          piechart1(response)
        
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }
    else {
      console.log("false check", isChecked)
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/Dashboard/PatientAutherizationDashboard?dateFrom=${fromDate}&dateTo=${toDate}`,
          { headers }
        )
        .then(response => {
          console.log('response.data: ', response)
          setRows(response)
          setDashboardinfo(response)
          setLoading(false)
          piechart(response)
        })
        .catch(error => {
          console.error('There was an error!', error)
        })
        axios
        .get(
          `${process.env.REACT_APP_API_URL}/Dashboard/ClaimsDashboard?dateFrom=${fromDate}&dateTo=${toDate}`,
          { headers }
        )
        .then(response => {
          console.log('response.data: ', response)
          setRows1(response)
          setLoading(false)
          piechart1(response)
        })
        .catch(error => {
          console.error('There was an error!', error)
        })
    }

  }

  const piechart = (response) => {
    const resData = response;
    const letterOfMedicalNecessity = [];
    console.log(resData,'response.data: ++___')
    const questionnaire = [];
    const progressNotes = [];
    const authRequest = [];
    const approved = [];
    const patientInfo = [];
    const totalRecords = [];
    for (let i = 0; i < resData.length; i++) {
      letterOfMedicalNecessity.push(resData[i].letterOfMedicalNecessity);
      questionnaire.push(resData[i].questionnaire);
      progressNotes.push(resData[i].progressNotes);
      authRequest.push(resData[i].authRequest);
      approved.push(resData[i].approved);
      patientInfo.push(resData[i].patientInfo);
      totalRecords.push(resData[i].totalRecords);

    }
if(resData=='Please Select the Dos Date'){
  setLetterOfMedicalNecessity(resData);
}else{
  setLetterOfMedicalNecessity(letterOfMedicalNecessity);

}
if(resData=='Please Select the Dos Date'){
  setQuestionnaire(resData);
}else{
  setQuestionnaire(questionnaire);

}
if(resData=='Please Select the Dos Date'){
  setPatientInfo(resData);
}else{
  setPatientInfo(patientInfo);

}
 
    setProgressNotes(progressNotes);
    setAuthRequest(authRequest);
    setApproved(approved);
    setTotalRecords(totalRecords);
  }
  const piechart1 = (response) => {
    const resData = response;
    console.log('testres',resData)
    console.log('testre____',resData)

    const compeletclaims = [];
    const paidclaims = [];
    const penndingclaims = [];

   
    for (let i = 0; i < resData.length; i++) {
      paidclaims.push(resData[i].total_payment);
      compeletclaims.push(resData[i].total_compeleted);
      penndingclaims.push(resData[i].total_submitted);
      
     

    }
  //   if(compeletclaims.length>0){
  //   setcompeletclaims(compeletclaims);
  //   }
  //   // setpenndingclaims(penndingclaims);

  //   if(penndingclaims.length>0)
  //  { setpenndingclaims(penndingclaims)
   
  // }
  //   if(paidclaims.length>0)
  //  { setpaidclaims(paidclaims);}
   
  }


  function handleClick() {
    // navigate("/Demographics");
    console.log(" Navigate to homes")
    setshow(true)

  }
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1100,
    height: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    // overflow: "auto",
    // overflows: "hide",
    // zIndex: "10",
    customHeader: {
      backgroundColor: 'black', // Apply your desired background color
      color: 'white', // Apply the desired text color
      fontWeight: 'bold',
    },
  };
  const style1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    height: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    overflow: "auto",
    // overflows: "hide",
    // zIndex: "10",
    customHeader: {
      backgroundColor: 'black', // Apply your desired background color
      color: 'white', // Apply the desired text color
      fontWeight: 'bold',
    },
  };

  const handleLabelClick = (label) => {
    console.log('Label clicked:', label);
    if (!isChecked && (!fromDate || !toDate)) {
      console.log("No action needed: checkbox unchecked and no date range selected.");
      return; // Do nothing
    }
    if (label !== `Total Records (${totalRecords})`) {
      setIsModalOpen(true);
    }
   if (label === `Patient Info (${patientInfo})`)
    {
      console.log("I m here");
      if (isChecked) {
        setSelectedLabel(label);
        setLoading(true)
        axios.get(`${process.env.REACT_APP_API_URL}/Dashboard/PatientInfoDetails?showAllPractices=${isAllPractics}&Isall=${isChecked}`, { headers })
          .then(response => {
            setLoading(false)
        setRaws(response)
        console.log('raaws', response)
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
        }
        else {
            setSelectedLabel(label);
      setLoading(true)
      axios
      .get(`${process.env.REACT_APP_API_URL}/Dashboard/PatientInfoDetails?dateFrom=${fromDate}&dateTo=${toDate}`, {
        headers
      }
      )
      .then((response) => {
        setLoading(false)

        setRaws(response)
        console.log('raaws', response)

      })

      .catch((error) => {
        console.error('There was an error!', error)
      })
    }
    }

    if (label === `Letter Of Medical Necessity (${letterOfMedicalNecessity})`)
    {
      if (isChecked) {
        setSelectedLabel(label);
        setLoading(true)
        axios.get(`${process.env.REACT_APP_API_URL}/Dashboard/LOMNDashboardDetails?showAllPractices=${isAllPractics}&Isall=${isChecked}`, { headers })
          .then(response => {
            setLoading(false)
        setRaws4(response)
        console.log('raaws', response)
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
        }
        else {
      setSelectedLabel(label);
      setLoading(true)
      axios
        .get(`${process.env.REACT_APP_API_URL}/Dashboard/LOMNDashboardDetails?dateFrom=${fromDate}&dateTo=${toDate}`, {
          headers
        }
        )
        .then((response) => {
          setLoading(false)
  
          setRaws4(response)
          console.log('raaws', response)
  
        })
  
        .catch((error) => {
          console.error('There was an error!', error)
        })
      }
    }

     if (label === `Questionnaire (${questionnaire})`)
    {
      if (isChecked) {
        setSelectedLabel(label);
        setLoading(true)
        axios.get(`${process.env.REACT_APP_API_URL}/Dashboard/QuestionnaireDetails?showAllPractices=${isAllPractics}&Isall=${isChecked}`, { headers })
          .then(response => {
            setLoading(false)
        setRaws3(response)
        console.log('raaws', response)
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
        }
      else {
      setSelectedLabel(label);
      setLoading(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/Dashboard/QuestionnaireDetails?dateFrom=${fromDate}&dateTo=${toDate}`, {
        headers
      }
      )
      .then((response) => {
        setLoading(false)

        setRaws3(response)
        console.log('raaws', response)

      })

      .catch((error) => {
        console.error('There was an error!', error)
      })
    }
    }

   if (label === `Progress Notes (${progressNotes})`)
    {
      if (isChecked) {
        setSelectedLabel(label);
        setLoading(true)
        axios.get(`${process.env.REACT_APP_API_URL}/Dashboard/ProgresNotesDetails?showAllPractices=${isAllPractics}&Isall=${isChecked}`, { headers })
          .then(response => {
            setLoading(false)
        setRaws1(response)
        console.log('raaws', response)
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
        }
        else {
      setSelectedLabel(label);
      setLoading(true)
      axios
        .get(`${process.env.REACT_APP_API_URL}/Dashboard/ProgresNotesDetails?dateFrom=${fromDate}&dateTo=${toDate}`, {
          headers
        }
        )
        .then((response) => {
          setLoading(false)
  
          setRaws1(response)
          console.log('raaws', response)
  
        })
  
        .catch((error) => {
          console.error('There was an error!', error)
        })
      }
    }

   if (label === `Submitted to LuxBilling (${authRequest1})`)
    {
      if (isChecked) {
        setSelectedLabel(label);
        setLoading(true)
        axios.get(`${process.env.REACT_APP_API_URL}/Dashboard/PatientAuthRequestDetails?showAllPractices=${isAllPractics}&Isall=${isChecked}`, { headers })
          .then(response => {
            setLoading(false)
        setRaws5(response)
        console.log('raaws', response)
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
        }
        else {
      setSelectedLabel(label);
      setLoading(true)
      axios
        .get(`${process.env.REACT_APP_API_URL}/Dashboard/PatientAuthRequestDetails?dateFrom=${fromDate}&dateTo=${toDate}`, {
          headers
        }
        )
        .then((response, index) => {
          setLoading(false) 
          setRaws5(response)
          const dashpatient = response[index].lastName+ " "+ response[index].firstName;
          setPatientName(dashpatient);
          console.log('dashhpatiern',dashpatient)
        })
  
        .catch((error) => {
          console.error('There was an error!', error)
        })
      }
    }

    if (label === `Approved (${approved1})`)
    {
      if (isChecked) {
        setSelectedLabel(label);
        setLoading(true)
        axios.get(`${process.env.REACT_APP_API_URL}/Dashboard/AprovedPatientDetails?showAllPractices=${isAllPractics}&Isall=${isChecked}`, { headers })
          .then(response => {
            setLoading(false)
        setRaws2(response)
        console.log('raaws', response)
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
        }
        else {
      setSelectedLabel(label);
      setLoading(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/Dashboard/AprovedPatientDetails?dateFrom=${fromDate}&dateTo=${toDate}`, {
        headers
      }
      )
      .then((response) => {
        setLoading(false)
        setRaws2(response)
        console.log('raaws', response)

      })

      .catch((error) => {
        console.error('There was an error!', error)
      })
    }
    }

  };

  const handleLabelClick1 = (label) => {
   
    if (!isChecked && (!fromDate || !toDate)) {
      console.log("No action needed: checkbox unchecked and no date range selected.");
      return; // Do nothing
    }
    // if (label !== `Total Records (${totalRecords1})`) {
    //   console.log('Label clicked___________:', label);
    //   setIsModalOpen1(true);
    // }
  


   
      if (label === `Pending Claims (${penndingclaims})`)
        {
          if (isChecked) {
            setSelectedLabel1(label);
            setIsModalOpen1(true);
            setLoading(true)
            axios.get(`${process.env.REACT_APP_API_URL}/Dashboard/ClaimssubmittedSummary?showAllPractices=${isAllPractics}&Isall=${isChecked}`, { headers })
              .then(response => {
                setLoading(false)
                setpanddingrows(response)
            console.log('raaws', response)
              })
              .catch(error => {
                console.error('Error fetching data:', error);
              });
            }
          else {
          setSelectedLabel1(label);
          setLoading(true)
        axios
          .get(`${process.env.REACT_APP_API_URL}/Dashboard/ClaimssubmittedSummary?dateFrom=${fromDate}&dateTo=${toDate}`, {
            headers
          }
          )
          .then((response) => {
            setLoading(false)
    
            setpanddingrows(response)
            console.log('raaws', response)
    
          })
    
          .catch((error) => {
            console.error('There was an error!', error)
          })
        }
        }
        
        if (label === `Completed Claims (${compeletclaims})`)
          {
            if (isChecked) {
              setSelectedLabel1(label);
              setIsModalOpen1(true);
              setLoading(true)
              axios.get(`${process.env.REACT_APP_API_URL}/Dashboard/ClaimscompeletedSummary?showAllPractices=${isAllPractics}&Isall=${isChecked}`, { headers })
                .then(response => {
                  setLoading(false)
                  setcompeleterows(response)
              console.log('raaws', response)
                })
                .catch(error => {
                  console.error('Error fetching data:', error);
                });
              }
            else {
            setSelectedLabel1(label);
            setLoading(true)
          axios
            .get(`${process.env.REACT_APP_API_URL}/Dashboard/ClaimscompeletedSummary?dateFrom=${fromDate}&dateTo=${toDate}`, {
              headers
            }
            )
            .then((response) => {
              setLoading(false)
      
              setcompeleterows(response)
              console.log('raaws', response)
      
            })
      
            .catch((error) => {
              console.error('There was an error!', error)
            })
          }
          }
      

        if (label === `Paid Claims (${paidclaims})`)
          {
            if (isChecked) {
              setSelectedLabel1(label);
              setIsModalOpen1(true);
              setLoading(true)
              axios.get(`${process.env.REACT_APP_API_URL}/Dashboard/ClaimspaymentSummary?showAllPractices=${isAllPractics}&Isall=${isChecked}`, { headers })
                .then(response => {
                  setLoading(false)
                  setpaymentrows(response)
              console.log('raaws', response)
                })
                .catch(error => {
                  console.error('Error fetching data:', error);
                });
              }
            else {
            setSelectedLabel1(label);
            setLoading(true)
          axios
            .get(`${process.env.REACT_APP_API_URL}/Dashboard/ClaimspaymentSummary?dateFrom=${fromDate}&dateTo=${toDate}`, {
              headers
            }
            )
            .then((response) => {
              setLoading(false)
      
              setpaymentrows(response)
              console.log('raaws', response)
      
            })
      
            .catch((error) => {
              console.error('There was an error!', error)
            })
          }
          }


  };



  const closeModopen = () => {
    setIsModalOpen(false)
  }
  const closeModopen1 = () => {
    setIsModalOpen1(false)
  }

  const handleCheckboxChange = (event) => {
    setIsChecked(true);
  };
  const [osapage, setOsapage] = React.useState(false);
  const [OpenLetter, setOpenLetter] = React.useState(false);
  const [OpenpriorAuth, setOpenpriorAuth] = React.useState(false);
  const [vobpage, setVobpage] = React.useState(false);
  const [vobpagepatientid, setVobpagepatientid] = useState();
  const [vobpage1, setVobpage1] = React.useState(false);
  const [opennewDisclaimer, setOpenDisclaimer] = React.useState(false);

  const [opendashprove, setopendashprove] = React.useState(false);
  const handlecolsedashprove = () => setopendashprove(false);
  const handleopendashprove = () => {
    reset();
    setopendashprove(true);
    setColumnID(0)
    // setSelectedPatient("")
  };
  const [openModal, setOpenModal] = React.useState(false);
  const handleCloseModal = () => setOpenModal(false);

  const handleOpen = () => {
    reset();
    setOpenModal(true);
    setColumnID(0)
    // setSelectedPatient("")
  };
  const openvobpage = () => {
    setVobpage(true);
    // reset()
  };
  const openvobpage1 = () => {
    setVobpage1(true);
    // setPatId(patientId)
    // reset()
  };
  const openosapage = () => {
    setOsapage(true);
    // reset()
  };
  const openLetter = () => {
    setOpenLetter(true);
    // reset()
  };
  const openpriorAuth = () => {
    setOpenpriorAuth(true);
    setOpenpriorAuth(true);
    // reset()
  };  
  const openDisclaimer = () => {
    setOpenDisclaimer(true);
    // reset()
  };


  const closevobpage = () => {
    setVobpage(false);
    // reset()
  };
  const closevobpage1 = () => {
    setVobpage1(false);
    // reset()
  };
  const closeosapage = () => {
    setOsapage(false);
    // reset()
  };
  const closeLetter = () => {
    setOpenLetter(false);
    // reset()
  };

  const closepriorAuth = () => {
    setOpenpriorAuth(false);
    // reset()
  };
  const closeDisclaimer = () => {
    setOpenDisclaimer(false);
    // reset()
  };
const handlePrint=()=>{
  window.print();
}
  const options = [
    <div>
      <Grid container>
        <Grid>
          <Button className="Button" onClick={openvobpage} variant="contained">
            VOB CheckList
          </Button>
        </Grid>
        <Grid style={{ marginTop: "5px" }}>
          <Button className="Button" onClick={openvobpage1} variant="contained">
            Auth-Request
          </Button>
        </Grid>
        <Grid style={{ marginTop: "5px" }}>
          <Button className="Button" onClick={openosapage} variant="contained">
            Screener
          </Button>
        </Grid>
        <Grid style={{ marginTop: "5px" }}>
          <Button className="Button" onClick={openLetter} variant="contained">
            Letter
          </Button>
        </Grid>
        <Grid style={{ marginTop: "5px" }}>
          <Button className="Button" onClick={openpriorAuth} variant="contained">
            Prior Auth
          </Button>
        </Grid>
        <Grid style={{ marginTop: "5px" }}>
          <Button className="Button" onClick={openDisclaimer} variant="contained">
            Disclaimer
          </Button>
        </Grid>
       
      </Grid>
    </div>,
  ];
  


  const [id, setid] = useState('')
  const [id1, setid1] = useState('')

  const [Resolve, setResolve] = useState('')
  
  const [nonapprovedNotification, setNonapprovedNotification] = useState(null)
  const [refreshapprove, setrefreshapprove,] = useState(false);
  function applyRefreshapprove() {
    setrefreshapprove(!refreshapprove)
  }
  const [refreshclaim, setrefreshcalim,] = useState(false);
  function applyRefreshclaim() {
    setrefreshcalim(!refreshclaim)
  }
   useEffect(() => {
    console.log('Notification open')
    setLoading(true)
    // setRefresh(true)


    axios
      .get(`${process.env.REACT_APP_API_URL}/Dashboard/GetAuthorizationFormApproved?showAllPractices=${isAllPractics}&Isall=${isChecked}`, { headers })
      .then(response => {
        console.log('testre__response.data: ', response)
        setApprovedNotification(response)
        setApproved1(response.length)

        // setTotalcount(response.length)
        setLoading(false)



      })
      .catch(error => {
        console.error('There was an error!', error)
      })

     
  }, [refreshapprove,isAllPractics,isChecked])
  useEffect(() => {
    console.log('Notification open')
    setLoading(true)
    // setRefresh(true)


    axios
      .get(`${process.env.REACT_APP_API_URL}/claims/ClaimsSummary?Isall=${isChecked}&showAllPractices=${isAllPractics}`, { headers })
      .then(response => {
        console.log('response.data: ', response)
          var res=response.filter(f=>f.claimsubmitted == true );
        setclaimNotification(res)
        const Pendingres=res?.length
        setpenndingclaims(Pendingres)
        var res1=response.filter(f=> f.compeleted == true && f.payment == false );
        setclaimNotification1(res1)
        const compeletedres=res1?.length
        setcompeletclaims(compeletedres)
         var res2 = response.filter(f=>f.payment == true);
         setclaimNotificationpayment(res2)
         const total_payment=res2?.length
         setpaidclaims(total_payment)
         console.log(total_payment,'testre__+')

        // setTotalcount(response.length)
        setLoading(false)



      })
      .catch(error => {
        console.error('There was an error!', error)
      })

     
  },[refreshclaim,isChecked,isAllPractics])
 
  const checkstyle1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: 500,
    bgcolor: "background.paper",
    border: "2px solid #356F60",
    boxShadow: 24,
    // overflow: "auto",
    // overflow: "auto",
     /* Other styles */
     scrollbarWidth: 'thin', // For Firefox
     scrollbarColor: '#356F60 transparent', // thumb color and track color
     '&::-webkit-scrollbar': {
       width: 8, /* Width of vertical scrollbar */
       height: 8, /* Height of horizontal scrollbar */
     },
     '&::-webkit-scrollbar-thumb': {
       backgroundColor: '#356F60', /* Color of the thumb */
       borderRadius: 10,
     },
     '&::-webkit-scrollbar-track': {
       backgroundColor: 'transparent', /* Color of the track */
     },
     /* Adjust the width for the horizontal scrollbar */
     '&::-webkit-scrollbar-thumb:horizontal': {
       width: 4, /* Width of horizontal scrollbar */
     },
    // zIndex: "10",
  };
  const checkstyle3 = {
    position: "absolute",
    top: "50%",
    // left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1100,
    height: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    overflow: "auto",
    overflows: "hide",
    // zIndex: "10",
  };
  const checkstyle2 = {
    position: "absolute",
    top: "50%",
    left: "60%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    height: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    overflow: "auto",
    // overflows: "hide",
    // zIndex: "10",
  };
  const [includetype, setIncludetype] = React.useState('')
  useEffect(() => {
    setIncludetype([{ label: 'Auth Request Submitted', value: 'Auth Request Submitted' }]);
  }, []); 
 
  
  // const onFilterSearchapporve = event => {
  //   setLoading(true);
   
  //   axios
  //     .get(`${process.env.REACT_APP_API_URL}/Dashboard/GetAuthorizationFormSubmitted`, { headers })
  //     .then(response => {
  //       console.log('response.data: ', response);
  //       setNonapprovedNotification(response);
  //       setLoading(false);
  //     })
  //     .catch(error => {
  //       console.error('There was an error!', error);
  //       setLoading(false);
  //     });
  // };
  // useEffect(() => {
  //   onFilterSearchapporve()
  // }, [])
  useEffect(() => {
    console.log('Notification open')
    setLoading(true)
    // setRefresh(true)


    axios
    .get(`${process.env.REACT_APP_API_URL}/Dashboard/GetAuthorizationFormSubmitted?showAllPractices=${isAllPractics}&Isall=${isChecked}`, { headers })
    .then(response => {
      console.log('response.data:___', response);
      setNonapprovedNotification(response);
       setAuthRequest1(response.length)

      setLoading(false);
    })
    .catch(error => {
      console.error('There was an error!', error);
      setLoading(false);
    });

     
  }, [refreshapprove,isChecked,isAllPractics])
  // useEffect(() => {
  //   setLoading(true)
    
  //     // .get(`${process.env.REACT_APP_API_URL}/Notifications/getNotificationsAppprovedOrSubmitted?notInclude=${'Auth Request Approved'}&include=${'Auth Request Submitted'}`, { headers })

  //   axios
  //   .get(`${process.env.REACT_APP_API_URL}/Notifications/getNotificationsAppprovedOrSubmitted`, { headers })

  //     .then(response => {
  //       console.log('response.data: ', response)
  //     setNonapprovedNotification(response)
  //     // setPracticeID(response.practiceId)
  //     // setTotalcount(response.length)
  //     setLoading(false)



  //     })
  //     .catch(error => {
  //       console.error('There was an error!', error)
  //     })

     
  // }, [refreshapprove])
  /////Claim///
  const [claimopen, setclaimopen] = React.useState(false);
  const handleclaimopen= () => {
    setclaimopen(true)
  };
  
  const handlecloseclaimopen = () => {
    setclaimopen(false)
  };


  /////////
  const [approve, setapprove] = React.useState(false);
  const handleapprove= () => {
    setapprove(true)
  };
  
  const handlecloseapprove = () => {
    setapprove(false)
  };
  const [approvedashbord, setapprovedasbord] = React.useState(false);
  const handleapprovedashbord= () => {
    setapprovedasbord(true)
  };
  
  const handlecloseapprovedashbord = () => {
    setapprovedasbord(false)
  };
   const [PriorPracticeID,setPriorPracticeID] =useState('')
  const [luxbillingpending, setluxbillingpending] = React.useState(false);
  const handleluxbillingpending= () => {
    setluxbillingpending(true)
  };
  
  const handleCloseluxbillingpending = () => {
    setluxbillingpending(false)
  };
  const alert = useAlert()
  
  const [refresh, setRefresh] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [Totalcount, setTotalcount] = useState(0)
  const [selectedRow, setselectedRow] = useState('')
  const [selectedindex, setselectedindex] = useState('')
  const handleRemove = selectedIndex => {
    const updatedNotification = approvedNotification.filter((_, index) => index !== selectedIndex)
    setApprovedNotification(updatedNotification)
    const updatedNotification1 = nonapprovedNotification.filter((_, index) => index !== selectedIndex)
    setNonapprovedNotification(updatedNotification1)
    setAuthRequest(updatedNotification1.length)

  }
  const [errorMessage, setErrorMessage] = useState(false);
  const saveData = data => {
    var postData = data
    postData.id = selectedRow
    postData.resolved = true
    // setSubmitting(true)
    if (!data.resolvedDate) {
      setErrorMessage(true);
      return;
    }
    axios
      .post(`${process.env.REACT_APP_API_URL}/Notifications/addNotifications`, postData, { headers })
      .then(response => {
        setid(null)
        approvedNotification.splice(selectedindex, 1)
        nonapprovedNotification.splice(selectedindex, 1)
        handleRemove(selectedindex)
        alert.success('Issue resolved successfully.', {
          type: 'success',
          onClose: () => {
            setTotalcount(Totalcount - 1)
            setSubmitting(false)
            setRefresh(true)
            applyRefreshapprove()
            reset()
          }
        })
      })
      .catch(error => {
        setSubmitting(false)
        alert.error(`Error ${error.message}`, {
          type: 'error'
        })
        setRefresh(true)
      })
    setRefresh(true)

  }
  const [expandedRowId, setExpandedRowId] = useState(null);

  const handleToggleExpansion = (index) => {
    // if (expandedRowId === rowId) {
    //   setExpandedRowId(null); // Collapse the expanded row if it's clicked again
    // } else {
      setExpandedRowId(prevIndex => (prevIndex === index ? null : index)); // Expand the clicked row
    // }
  };
  const [expandedRowIndex, setExpandedRowIndex] = useState(null); // Track the expanded row's index

  const handleToggleExpansion1 = (index) => {
    setExpandedRowIndex(prevIndex => (prevIndex === index ? null : index)); // Toggle the row expansion
  };
  /////////Claim////////
  const [expandedRowIdclaim, setExpandedRowIdclaim] = useState(null);

  const handleToggleExpansionclaim = (rowIdclaim) => {
    if (expandedRowIdclaim === rowIdclaim) {
      setExpandedRowIdclaim(null); // Collapse the expanded row if it's clicked again
    } else {
      setExpandedRowIdclaim(rowIdclaim); // Expand the clicked row
    }
  };

  const [expandedRowIdclaim1, setExpandedRowIdclaim1] = useState(null);

  const handleToggleExpansionclaim1 = (rowIdclaim1) => {
    if (expandedRowIdclaim1 === rowIdclaim1) {
      setExpandedRowIdclaim1(null); // Collapse the expanded row if it's clicked again
    } else {
      setExpandedRowIdclaim1(rowIdclaim1); // Expand the clicked row
    }
  };

  const [expandedRowIdclaim2, setExpandedRowIdclaim2] = useState(null);

  const handleToggleExpansionclaim2 = (rowIdclaim2) => {
    if (expandedRowIdclaim2 === rowIdclaim2) {
      setExpandedRowIdclaim2(null); // Collapse the expanded row if it's clicked again
    } else {
      setExpandedRowIdclaim2(rowIdclaim2); // Expand the clicked row
    }
  };


   return (
    <React.Fragment>
      {openModal ? (
        <>
          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style1}>
              <Headroom>
                <Grid
                  container
                  style={{ backgroundColor: '#356F60', position: 'sticky' }}
                >
                  <Grid item xs={3}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        height: '2rem'
                      }}
                    >
                      <DialogTitle
                        style={{
                          flex: 1,
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="standard" fontWeight="bold" color="#fff"> Add Authorization</Typography>
                      </DialogTitle>
                 
                    </div>
                  </Grid>
                  <Grid item xs={2.9}>
              <Typography style={{color:'white', marginTop: '8px',float:'right',fontWeight:'bold'}}>Patient Name:</Typography>
              </Grid>
              <Grid item xs={2}>
              <Typography style={{color:'white', marginTop: '8px',}}>{patientName}</Typography>

             
              </Grid>
              <Grid item xs={2.5}>
              <Typography style={{color:'white', marginTop: '8px',float:'right',fontWeight:'bold'}}>ID:</Typography>
              </Grid>
              <Grid item xs={1}>
              <Typography style={{color:'white', marginTop: '8px',}}>{columnID}</Typography>

             
              </Grid>
                  <Grid item xs={0.5} >
                  <CancelOutlinedIcon
                        onClick={handleCloseModal}
                        fontSize="large"
                        color="red"
                        style={{float:'right',
                          color: 'red',
                          paddingRight: '5px',
                          cursor: 'pointer',
                          // marginLeft: 'auto',
                          // marginRight: '1.5rem'
                        }}
                      ></CancelOutlinedIcon>
                  </Grid>

                </Grid>
              </Headroom>
              <Grid style={{ backgroundColor: '#f3f3f4', margin: '1.3rem auto', border: '1px solid #326C1D', borderRadius: '10px', width:'98%'}}>
            <Grid >

            <AuthorizationRegistration callingFrom="dashboard" Dashboard={patientName} AutpattientID={AutpattientID} dashID={columnID} CloseModal={handleCloseModal}></AuthorizationRegistration>
            <ToastContainer
                position="top-center"
                autoClose={1500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
            </Grid>
              </Grid>
            </Box>
          </Modal>
         
          {/* ////////// */}
        


        </>

      ) : (
        <div className="page-content">
           
           <Modal
          open={approvelux}
          onClose={handlecloseapprovelux}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          visible={true} title={'Title'} footer={'Footer'}
        >
          <Box sx={checkstyle1}>

          <Headroom>
            <Grid
                  container
                  style={{ backgroundColor: '#356F60'}} 
                >
                  <Grid item xs={12} sm={12} xl={12}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        height: '3rem'
                      }}
                    >
                      <DialogTitle
                        style={{
                          flex: 1,
                          textAlign: 'center',
                        }}
                      >
                        <h1
                          style={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            color: '#fff',
                            textDecoration: 'underline',
                            fontSize:'1.5rem'

                          }}
                        >
                         Prior_auth & VOB

                        </h1>
                      </DialogTitle>
                      <Typography style={{color:'white',marginRight:'10px'}}>Patient Name:</Typography>
                      <Typography style={{color:'white',fontWeight:'bold',marginRight:'60px'}}>{patientNamee}</Typography>
                      
                      <Typography style={{color:'white',marginRight:'10px'}}>ID:</Typography>
                      <Typography style={{color:'white',fontWeight:'bold',marginRight:'60px'}}>{columnID}</Typography>
                     
                      <CancelOutlinedIcon
                        onClick={handlecloseapprovelux}
                        fontSize="large"
                        color="red"
                        style={{
                          color: 'red',
                          paddingRight: '5px',
                          cursor: 'pointer',
                          // marginLeft: 'auto',
                          // marginRight: '1.5rem'
                        }}
                      ></CancelOutlinedIcon>
                    </div>
                  </Grid>
                </Grid>
              </Headroom>


            <Grid item xs={12}>
              <Card bordered={false} style={{ overflow: 'auto',
              /* Other styles */
     scrollbarWidth: 'thin', // For Firefox
     scrollbarColor: '#356F60 transparent', // thumb color and track color
     '&::-webkit-scrollbar': {
       width: 8, /* Width of vertical scrollbar */
       height: 8, /* Height of horizontal scrollbar */
     },
     '&::-webkit-scrollbar-thumb': {
       backgroundColor: '#356F60', /* Color of the thumb */
       borderRadius: 10,
     },
     '&::-webkit-scrollbar-track': {
       backgroundColor: 'transparent', /* Color of the track */
     },
     /* Adjust the width for the horizontal scrollbar */
     '&::-webkit-scrollbar-thumb:horizontal': {
       width: 4, /* Width of horizontal scrollbar */
     },
    // zIndex: "10",
              height: '70vh' }}>
              
              <Prior_auth formDid={formDid}  columnID={columnID} handleClose4={handlecloseapprovelux}  patientNameee={patientNamee} AutpattientID1={AutpattientID1} Markaporoved={Markaporoved} 
                  updateHealthHistory={(id, authId) => {
                    console.log('setting', id)
                    setValue('approved', true)
                    setColumnIDsubluxbilling(authId)
                    // setapproveid(authId)
                  }}
                  style={{ height: '40vh' }}

                ></Prior_auth>
              </Card>
              <ToastContainer
                position="bottom-center"
                autoClose={1500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
            </Grid>


          </Box>
        </Modal>
        <Modal
          open={approve}
          onClose={handlecloseapprove}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          visible={true} title={'Title'} footer={'Footer'}
        >
          <Box sx={checkstyle1}>
          <Headroom>
            <Grid
                  container spacing={0}
                  style={{ backgroundColor: '#356F60',
                    
                    alignItems: 'center',
                    
                    height: 'auto'
                   }}
                >
                   <Grid item  xs={12}  >
                      <CancelOutlinedIcon
                        onClick={handlecloseapprove}
                        fontSize="medium"
                        color="red"
                        style={{
                          color: 'red',
                         
                          float:"right",
                          cursor: 'pointer',
                          // marginLeft: 'auto',
                          // marginRight: '1.5rem'
                        }}
                      ></CancelOutlinedIcon>
                    
                  </Grid>
                  <Grid item  xs={11} sm={8} md={4} lg={4} mt={-3}>
                   
                  
                      {/* <DialogTitle
                        // style={{
                        //   flex: 1,
                        //   textAlign: 'center',
                        // }}
                      >
                        <h1
                          style={{
                            fontWeight: 'bold',
                           
                            color: '#fff',
                            textDecoration: 'underline',
                            fontSize:'2rem'

                          }}
                        >
                         Prior_auth & VOB

                        </h1>
                      </DialogTitle> */}
                      <Typography style={{
                            fontWeight: 'bold',
                           
                            color: '#fff',
                            textDecoration: 'underline',
                            fontSize:'25px'}}>Prior auth & VOB</Typography>
                      </Grid>
                      <Grid item  xs={1.3} >
                      <Typography style={{color:'white',fontWeight:'bold'}}>Patient Name: </Typography>
                      </Grid>
                      <Grid item  xs={4} sm={4} md={3} lg={3} >
                      <Typography style={{color:'white',fontWeight:'bold'}}>{patinetnameopenauth}</Typography>
                      </Grid>
                      <Grid item  xs={0.4}  >
                      <Typography style={{color:'white',fontWeight:'bold'}}>ID:</Typography>
                      </Grid>
                      <Grid item  xs={3} sm={2} md={2} lg={2} >
                      <Typography style={{color:'white'}}> {authId}</Typography>
                      </Grid>
                      
                      
                </Grid>
              </Headroom>
            <Grid item xs={12}>
              <Card bordered={false} style={{ overflow: 'auto',
              /* Other styles */
     scrollbarWidth: 'thin', // For Firefox
     scrollbarColor: '#356F60 transparent', // thumb color and track color
     '&::-webkit-scrollbar': {
       width: 8, /* Width of vertical scrollbar */
       height: 8, /* Height of horizontal scrollbar */
     },
     '&::-webkit-scrollbar-thumb': {
       backgroundColor: '#356F60', /* Color of the thumb */
       borderRadius: 10,
     },
     '&::-webkit-scrollbar-track': {
       backgroundColor: 'transparent', /* Color of the track */
     },
     /* Adjust the width for the horizontal scrollbar */
     '&::-webkit-scrollbar-thumb:horizontal': {
       width: 4, /* Width of horizontal scrollbar */
     },
    // zIndex: "10",
              height: '70vh' }}>
              
              <Prior_auth  columnID={authId}  handleClose4={handlecloseapprove} patientNameee={patinetnameopenauth} AutpattientID1={patinetIdopenauth}    Markaporoved={Markaporovedopenauth} 
                  updateHealthHistory={(id, authId) => {
                    console.log('setting', id)
                    setValue('approved', true)
                    setColumnIDsubluxbilling(authId)
                    // setapproveid(authId)
                  }}
                  style={{ height: '40vh' }}

                ></Prior_auth>
              </Card>
              <ToastContainer
                position="bottom-center"
                autoClose={1500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
            </Grid>


          </Box>
        </Modal>
      
        <Modal
          open={luxbillingpending}
          onClose={handleCloseluxbillingpending}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          visible={true} title={'Title'} footer={'Footer'}
        >
          <Box sx={checkstyle1}>
          <Headroom>
            <Grid
                  container
                  style={{ backgroundColor: '#356F60', position: 'sticky' }}
                >
                  <Grid item xs={12} sm={12} xl={12}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        height: '3rem'
                      }}
                    >
                      <DialogTitle
                        style={{
                          flex: 1,
                          textAlign: 'center',
                        }}
                      >
                        <h1
                          style={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            color: '#fff',
                            textDecoration: 'underline',
                            fontSize:'2rem'

                          }}
                        >
                         Prior_auth & VOB

                        </h1>
                      </DialogTitle>
                      <Typography style={{color:'white',marginRight:'10px'}}>Patient Name:</Typography>
                      <Typography style={{color:'white',fontWeight:'bold',marginRight:'60px'}}>{patinetnameopenauth1}</Typography>
                      
                      <Typography style={{color:'white',marginRight:'10px'}}>ID:</Typography>
                      <Typography style={{color:'white',fontWeight:'bold',marginRight:'60px'}}>{authId2}</Typography>
                     
                      <CancelOutlinedIcon
                        onClick={handleCloseluxbillingpending}
                        fontSize="large"
                        color="red"
                        style={{
                          color: 'red',
                          paddingRight: '5px',
                          cursor: 'pointer',
                          // marginLeft: 'auto',
                          // marginRight: '1.5rem'
                        }}
                      ></CancelOutlinedIcon>
                    </div>
                  </Grid>
                </Grid>
              </Headroom>
        
        
  
              <Grid item xs={12} >
              {/* <AuthorizationRegistration callingFrom={"Approved"} columnID={authId2}  CloseModal={handleCloseluxbillingpending}></AuthorizationRegistration> */}
              <Card bordered={false} style={{ overflow: 'auto',
              /* Other styles */
     scrollbarWidth: 'thin', // For Firefox
     scrollbarColor: '#356F60 transparent', // thumb color and track color
     '&::-webkit-scrollbar': {
       width: 8, /* Width of vertical scrollbar */
       height: 8, /* Height of horizontal scrollbar */
     },
     '&::-webkit-scrollbar-thumb': {
       backgroundColor: '#356F60', /* Color of the thumb */
       borderRadius: 10,
     },
     '&::-webkit-scrollbar-track': {
       backgroundColor: 'transparent', /* Color of the track */
     },
     /* Adjust the width for the horizontal scrollbar */
     '&::-webkit-scrollbar-thumb:horizontal': {
       width: 4, /* Width of horizontal scrollbar */
     },
    // zIndex: "10",
              height: '70vh' }}>
           
            <Prior_auth  columnID={authId2} PriorPracticeID={PriorPracticeID} columnIDcpt={authIdcpt}  handleClose4={handleCloseluxbillingpending} patientNameee={patinetnameopenauth1} AutpattientID1={patinetIdopenauth1}    Markaporoved={Markaporovedopenauth}
          //  AutpattientID1={AutpattientID}
            updateHealthHistory={(id, authId) => {
              // setautoClosePage(false)
              console.log('setting', id)
              setValue('authRequest', true)
              setColumnID(authId)
              setValue('id',authId)
              // setauthRequestid(authId)
            }}
            ></Prior_auth>
              </Card>

              <ToastContainer
                position="bottom-center"
                autoClose={1500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
             
            </Grid>

     
              


           

          </Box>
        </Modal>
        <Modal
          open={approvedashbord}
          onClose={handlecloseapprovedashbord}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          visible={true} title={'Title'} footer={'Footer'}
        >
          <Box sx={checkstyle1}>
          <Headroom>
            <Grid
                  container
                  style={{ backgroundColor: '#356F60', position: 'sticky' }}
                >
                  <Grid item xs={12} sm={12} xl={12}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        height: '3rem'
                      }}
                    >
                      <DialogTitle
                        style={{
                          flex: 1,
                          textAlign: 'center',
                        }}
                      >
                        <h1
                          style={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            color: '#fff',
                            textDecoration: 'underline',
                            fontSize:'2rem'

                          }}
                        >
                         Prior_auth & VOB

                        </h1>
                      </DialogTitle>
                      <Typography style={{color:'white',marginRight:'10px'}}>Patient Name:</Typography>
                      <Typography style={{color:'white',fontWeight:'bold',marginRight:'60px'}}>{patientName}</Typography>
                      
                      <Typography style={{color:'white',marginRight:'10px'}}>ID:</Typography>
                      <Typography style={{color:'white',fontWeight:'bold',marginRight:'60px'}}>{columnID}</Typography>
                     
                      <CancelOutlinedIcon
                        onClick={handlecloseapprovedashbord}
                        fontSize="large"
                        color="red"
                        style={{
                          color: 'red',
                          paddingRight: '5px',
                          cursor: 'pointer',
                          // marginLeft: 'auto',
                          // marginRight: '1.5rem'
                        }}
                      ></CancelOutlinedIcon>
                    </div>
                  </Grid>
                </Grid>
              </Headroom>
            <Grid item xs={12}>
              <Card bordered={false} style={{ overflow: 'auto',
              /* Other styles */
     scrollbarWidth: 'thin', // For Firefox
     scrollbarColor: '#356F60 transparent', // thumb color and track color
     '&::-webkit-scrollbar': {
       width: 8, /* Width of vertical scrollbar */
       height: 8, /* Height of horizontal scrollbar */
     },
     '&::-webkit-scrollbar-thumb': {
       backgroundColor: '#356F60', /* Color of the thumb */
       borderRadius: 10,
     },
     '&::-webkit-scrollbar-track': {
       backgroundColor: 'transparent', /* Color of the track */
     },
     /* Adjust the width for the horizontal scrollbar */
     '&::-webkit-scrollbar-thumb:horizontal': {
       width: 4, /* Width of horizontal scrollbar */
     },
    // zIndex: "10",
              height: '70vh' }}>
              
              <Prior_auth abc={'abc'} columnID={columnID}  handleClose4={handlecloseapprovedashbord} patientNameee={patientNamee?patientNamee:patientName} AutpattientID1={AutpattientID}    Markaporoved={Markaporovedopenauth} 
                  updateHealthHistory={(id, authId) => {
                    console.log('setting', id)
                    setValue('approved', true)
                    setColumnID(authId)
                    // setapproveid(authId)
                  }}
                  style={{ height: '40vh' }}

                ></Prior_auth>
              </Card>
              <ToastContainer
                position="top-center"
                autoClose={1500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
            </Grid>


          </Box>
        </Modal>

        {/* /////// Claim Open */}
        <Modal
          open={claimopen}
          onClose={handlecloseclaimopen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          visible={true} title={'Title'} footer={'Footer'}
        >
          <Box sx={checkstyle1}>
          <Headroom>
            <Grid
                  container spacing={0}
                  style={{ backgroundColor: '#356F60',
                    
                    alignItems: 'center',
                    
                    height: 'auto'
                   }}
                >
                   <Grid item  xs={12}  >
                      <CancelOutlinedIcon
                        onClick={handlecloseclaimopen}
                        fontSize="medium"
                        color="red"
                        style={{
                          color: 'red',
                         
                          float:"right",
                          cursor: 'pointer',
                          // marginLeft: 'auto',
                          // marginRight: '1.5rem'
                        }}
                      ></CancelOutlinedIcon>
                    
                  </Grid>
                  <Grid item  xs={11} sm={8} md={4} lg={4} mt={-3}>
                   
                  
                      {/* <DialogTitle
                        // style={{
                        //   flex: 1,
                        //   textAlign: 'center',
                        // }}
                      >
                        <h1
                          style={{
                            fontWeight: 'bold',
                           
                            color: '#fff',
                            textDecoration: 'underline',
                            fontSize:'2rem'

                          }}
                        >
                         Prior_auth & VOB

                        </h1>
                      </DialogTitle> */}
                      <Typography style={{
                            fontWeight: 'bold',
                           
                            color: '#fff',
                            textDecoration: 'underline',
                            fontSize:'25px'}}>Claim Submission</Typography>
                      </Grid>
                      <Grid item  xs={1.4}  >
                      <Typography style={{color:'white',fontWeight:'bold'}}>Patient Name: </Typography>
                      </Grid>
                      <Grid item  xs={2} sm={2} >
                      <Typography style={{color:'white',fontWeight:'bold'}}>{patinetnameopenclaim}</Typography>
                      </Grid>
                      <Grid item  xs={0.4}  >
                      <Typography style={{color:'white',fontWeight:'bold'}}>ID:</Typography>
                      </Grid>
                      <Grid item  xs={2} >
                      <Typography style={{color:'white'}}> {claimId}</Typography>
                      </Grid>
                      
                      
                </Grid>
              </Headroom>
            <Grid item xs={12}>
              <Card bordered={false} style={{ overflow: 'auto',
              /* Other styles */
     scrollbarWidth: 'thin', // For Firefox
     scrollbarColor: '#356F60 transparent', // thumb color and track color
     '&::-webkit-scrollbar': {
       width: 8, /* Width of vertical scrollbar */
       height: 8, /* Height of horizontal scrollbar */
     },
     '&::-webkit-scrollbar-thumb': {
       backgroundColor: '#356F60', /* Color of the thumb */
       borderRadius: 10,
     },
     '&::-webkit-scrollbar-track': {
       backgroundColor: 'transparent', /* Color of the track */
     },
     /* Adjust the width for the horizontal scrollbar */
     '&::-webkit-scrollbar-thumb:horizontal': {
       width: 4, /* Width of horizontal scrollbar */
     },
    // zIndex: "10",
              height: '70vh' }}>
               
              
              <ClaimRegistration    claimId={claimId}  CloseModal={handlecloseclaimopen} Namee={patinetnameopenclaim} patientId={patinetIdopenclaim} applyRefresh={applyRefreshclaim} 
                  
                  style={{ height: '40vh' }}

                ></ClaimRegistration>
              </Card>
              <ToastContainer
                position="bottom-center"
                autoClose={1500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
            </Grid>


          </Box>
        </Modal>

        {/* //////ClaimClose/// */}
          <Grid container spacing={1}>

          <Grid item xs={2}  style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} >
          <div style={{ display: 'flex', alignItems: 'center',height:'27px'}}>
              <Checkbox
                value={isChecked}
                style={{ marginTop: '20px' }}
                onChange={e => {
                  setIsChecked(e.target.checked)
                 
                  console.log('Test', e.target.checked)
                }}
           
              />
             <Typography style={{marginLeft:'10px',marginTop:'15px',fontWeight:'bold'}}>All Dates</Typography>
          </div> 
                  <div style={{ display: 'flex', alignItems: 'center',height:'27px' }}>
                    {userType=='Front Office'?'':(<>
                             <Checkbox
                                  value={isAllPractics}
                                  style={{ marginTop: '20px' }}
                                  onChange={e => {
                                    setisAllPractics(e.target.checked)
                                  }}
                                />
                       <Typography style={{marginLeft:'10px',marginTop:'15px',fontWeight:'bold'}}>All Practices</Typography>

                       </>  
                       )}       
                            
                  </div>

            </Grid>
            
            {/* <Grid item xs={2}  style={{ display: 'flex', alignItems: 'center',}} >
              {/* <Checkbox
                value={isAllPractics}
                style={{ marginTop: '20px' }}
                onChange={e => {
                  setisAllPractics(e.target.checked)
                }}
               
               
              />
            <Typography style={{marginLeft:'10px',marginTop:'15px'}}>All Practices</Typography>
            
            </Grid> */}

            {/* <Grid item xs = {1}>
          </Grid> */}


<Grid item xs={3.5} >
              <Typography> From Date </Typography>
              <Controller
                name='fromDate'
                // {...register('fromDate')}
                control={control}
                render={({ field }) => (
                  <TextField
                    // isClearable
                    size='small'
                    type='date'
                    disabled={isChecked == true ? (true) : (false)}
                    {...field}
                    fullWidth
                    value={fromDate}
                    // error={errors.fromDate}
                    onChange={e => {
                      setValue('fromDate', e.target.value)
                      setfromDate(e.target.value)

                    }}
                  />
                )}
              />

            </Grid>

            <Grid item xs={3.5} >
              <Typography> To Date  </Typography>
              <Controller
                name='toDate'
                // {...register('toDate')}
                control={control}
                render={({ field }) => (
                  <TextField
                    // isClearable
                    size='small'
                    type='date'
                    disabled={isChecked == true ? (true) : (false)}
                    {...field}
                    fullWidth
                    value={toDate}
                    // error={errors.fromDate}
                    onChange={e => {
                      setValue('toDate', e.target.value)
                      settoDate(e.target.value)

                    }}
                  />
                )}
              />

            </Grid>
            <Grid item xs={0.5} ></Grid>

            <Grid item xs={2} >
              <Button
                className="Button"
                variant="contained"
                onClick={onFilterSearch}
                fullWidth
                style={{
                  float: 'right',
                  // width: '150px',
                  height: '35px',
                  marginTop: '25px',

                  fontSize: '14px',
                  color: 'white',
                  // backgroundColor:'#004cab'
                }}
              >
                <SearchOutlinedIcon
                  fontSize='medium'
                  style={{ color: 'white', paddingBottom: '1px' }}
                ></SearchOutlinedIcon>
                Search
              </Button>
            </Grid>
            
             </Grid>
          <div>
            {isLoading ? (
              <div style={{ margin: '15rem', textAlign: 'center' }}>
                <CircularProgress />
              </div>
            ) : (
              <>
           

            
                <Container fluid={true}>
                  <Grid container spacing={2} style={{ marginTop: '5px' }}>
                    {/* <Grid item xs={1}></Grid> */}
                    <Grid item xs={0.1}></Grid>

                    {HideData && (
                  <Grid item xs={5.7} style={{ border: '2px solid #377562', padding: '10px',borderRadius:'15px'}} >
                      <div >
                     

                        {rows === null || rows.length === 0 ? ( <Typography>No Record found</Typography>) : (
                          <>
                           <Typography style={{fontSize:'16px',fontWeight:'bold',backgroundColor:'#377562',color:'white',textAlign:'center',borderRadius:'15px'}}>Authorization</Typography>
                            {labelData.map((labelObj) => (
                              <Box key={labelObj.label} display="flex" alignItems="center" mb={1} style={{
                               
                                marginTop: '3px',
                               
                                      }}  >
                                <div onClick={() => handleLabelClick(labelObj.label)}
                                  style={{
                                    width: '12px',
                                    height: '12px',
                                    backgroundColor: labelObj.color,
                                    marginRight: '10px',
                                  }}
                                ></div>
                                {labelObj.label !== `Total Records (${totalRecords})` ? (
                                  <Typography style={{ cursor: 'pointer', display: 'block', borderRadius: '5px' }} onClick={() => handleLabelClick(labelObj.label)}>
                                    {labelObj.label}
                                  </Typography>
                                ) : (
                                  <Typography>{labelObj.label}</Typography>
                                )}
                              </Box>
                            ))}

                         



                          </>
                        )}

                      </div>
                        
                    </Grid>
                    )}
                    <Grid item xs={0.3}></Grid>
                    {HideData && (

                    <Grid item xs={5.7} style={{ border: '2px solid #377562', padding: '10px',borderRadius:'15px'}} >
                      <div>
                        {rows1 === null || rows1.length === 0 ? ( <Typography>No Record found</Typography>) : (
                          <>
                          <Typography style={{fontSize:'16px',fontWeight:'bold',backgroundColor:'#377562',color:'white',textAlign:'center',borderRadius:'15px'}}>Claims</Typography>

                            {labelData1.map((labelObj1) => (
                              <Box key={labelObj1.label} display="flex" alignItems="center" mb={1} style={{
                               
                        marginTop: '3px',
                       
                              }} >
                                <div 
                                onClick={() => handleLabelClick1(labelObj1.label)}
                                  style={{
                                    width: '12px',
                                    height: '12px',
                                    backgroundColor: labelObj1.color,
                                    marginRight: '10px',
                                  }}
                                ></div>
                                 <Typography style={{ cursor: 'pointer', display: 'block', borderRadius: '5px' }} 
                                  onClick={() => handleLabelClick1(labelObj1.label)}
                                  >
                                    {labelObj1.label}
                                  </Typography>
                              </Box>
                            ))}

                         



                          </>
                        )}

                      </div>
                        
                    </Grid>
                     )} 
                    {/* <Grid item xs={2}  >
                      {/* <div className='pie-chart'>
                        <div >
                          <Chart
                            type='pie'
                            width={450}
                            height={280}
                            series={combinedData}
                            options={{
                              labels: ['HealthHistory', 'Questionnaire', 'ProgressNotes', 'AuthRequest', 'Approved', 'PatientInfo', 'TotalRecords'],
                              title: {
                                text: ''
                              },
                              noData: {
                                text: 'No Record of Authorization'
                              },
                              dataLabels: {
                                formatter: function (val, opts) {
                                  return opts.w.config.series[opts.seriesIndex]
                                },
                              },
                            }}
                          >
                          </Chart>
                        </div>
                      </div> 
                    </Grid> */}
                   
                  </Grid>

                  <Grid container spacing={1} style={{ marginTop: '5px' }}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Grid container spacing={1}>
                     <Grid item xs={11}>
                     <Typography style={{fontSize:'16px',fontWeight:'bold',color:'black'}} >Alert-Message Completed Prior-auth/VOB</Typography>

                     </Grid>

                     <Grid item xs={1}>
                     
                <CachedOutlinedIcon
                onClick={event => {
                  applyRefreshapprove()
                 }}
               fontSize='medium'
               style={{
                 color: '#3C7F6B',
                //  marginRight: '-25px',
                 paddingRight: '0px',
                 paddingBottom: '1px'
               }}
             ></CachedOutlinedIcon>
          
                     </Grid>
                    </Grid>
                       
                   
                      <Paper
                      variant='outlined'
                      square
                      style={{
                        backgroundColor: '#F0F6F8',
                        borderRadius: '15px',
                        marginTop: '3px',
                        borderColor: '#377562',
                        maxWidth: '100%',
                        padding: '.5rem',
                        marginBottom: '0.5rem',
                        marginLeft: '0 .2rem',
                        height:300,
                        overflow: "auto",
                        /* Other styles */
                        scrollbarWidth: 'thin', // For Firefox
                        scrollbarColor: '#356F60 transparent', // thumb color and track color
                        '&::-webkit-scrollbar': {
                          width: 8, /* Width of vertical scrollbar */
                          height: 8, /* Height of horizontal scrollbar */
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: '#356F60', /* Color of the thumb */
                          borderRadius: 10,
                        },
                        '&::-webkit-scrollbar-track': {
                          backgroundColor: 'transparent', /* Color of the track */
                        },
                        /* Adjust the width for the horizontal scrollbar */
                        '&::-webkit-scrollbar-thumb:horizontal': {
                          width: 4, /* Width of horizontal scrollbar */
                        },
                       // zIndex: "10",
                       
                      }}
                    >
                       {approvedNotification != null && approvedNotification.length > 0
                  ? approvedNotification.map((row, index) => {
                    const isRowExpanded = expandedRowId === index;
                      return(
                        <Paper
                      variant='outlined'
                      square
                      style={{
                        backgroundColor:'white',
                        borderRadius: '10px',
                        // marginTop: '3px',
                        borderColor: '#377562',
                        maxWidth: '100%',
                        padding: '.3rem',
                        marginBottom: '0.3rem',
                        marginLeft: '0 .2rem',
                      }}
                    >
                        <Grid container spacing={1}>
                        {/* <Grid item xs={2}>
                      <Typography style={{color:'black',fontWeight:'bold'}}>{row.patientName}'S</Typography>

                      </Grid> */}
                        <Grid item xs={0.7}>
                          <Avatar sx={{ width: 22, height: 22,bgcolor: '#3C7F6B' }}>{row.patientName?.charAt(0).toUpperCase()}</Avatar>
                        </Grid>

                      <Grid item xs={10.3}>
                      {/* <Typography
                  style={{ color: 'black', fontSize: '14px' }}
                  onClick={() => handleToggleExpansion(row.id)} 
                >
                  <b>{row.patientName}'s</b> {row.description}
                </Typography> */}
                      {/* {expandedRowId === row.id ? (<> */}
                      <Typography style={{color:'black',fontSize:'14px'}} 
                      onClick={() => 
                        {
                          handleToggleExpansion(null)
                        }}
                        // {row.description}
                                ><b>{row.patientName}'s</b> Authorization Request Approved.</Typography>
                                <Typography style={{color:'green'}}> <b>{row.practiceName}</b> </Typography>
                        
                        {/* </>):(<>
                      <Typography style={{color:'black',fontSize:'14px'}}  onClick={() => {
                               handleToggleExpansion(row.id)
                              }}><b>{row.patientName}'s</b> Authorization Request Approved.</Typography>
                        
                        </>)} */}

                      </Grid>
                      
                      <Grid item xs={1} >
                      {isRowExpanded ? ( <>
                          
                              <ExpandLessIcon
                                onClick={() => handleToggleExpansion(index)}
                                fontSize='medium'
                                style={{
                                  color: 'black',
                                  paddingRight: '5px',
                                  paddingBottom: '2px'
                                }}
                                >
                                </ExpandLessIcon>
                        </>):(
                        <>
                               <ExpandMoreIcon
                              onClick={() => handleToggleExpansion(index)} 
                                fontSize='medium'
                                style={{
                                  color: 'black',
                                  paddingRight: '5px',
                                  paddingBottom: '2px'
                                }}
                                >
                                </ExpandMoreIcon>
                        </>)}
                            
                            </Grid>
                           
                            <Grid container spacing={1} >
                            {isRowExpanded && (
                <Grid container spacing={1}>
                  {/* <Grid item xs={2}>
                      <Typography style={{fontWeight:'bold',color:'black',fontSize:'14px',marginLeft:'15px',marginTop:'5px'}}>Comments:</Typography>

                      </Grid>
                      <Grid item xs={10}>
                      <Typography style={{color:'black',fontSize:'14px',marginLeft:'15px',marginTop:'5px'}}>{row.comments}</Typography>

                      </Grid> */}
                      <Grid item xs={7}></Grid>
                      <Grid item xs={3}>
                      <Button
                            style={{
                              backgroundColor: 'green',
                              color: 'white',
                              float: 'right',
                              width: '120px',
                              height: '28px',
                              marginTop:'5px',
                              marginRight:'25px'


                            }}
                            size='small'
                            onClick={() => {
                              setauthId(row.authId)
                              setpatinetIdopenauth(row.patientId)
                              setpatinetnameopenauth(row.patientName)

                              setMarkaporovedopenauth(row.approved)
                             handleapprove()
                             }}
                           >
                              
                            <Typography
                              fontSize="small"
                              style={{ color: "#fff", }}
                            >Open Auth</Typography>

                          </Button>

                      </Grid>
                      {/* <Grid item xs={2} >
                                      <Button
                                       className='Button'
                                        onClick={() => {
                                          setselectedRow(row.id)
                                          setselectedindex(index)
                                          setResolve(row.id)
                                        }}
                                        variant='gradient'
                                        style={{
                                          marginBottom: '10px',
                                          float: 'right',
                                          width: '87px',
                                          height: '28px',
                                          backgroundColor: '#56CA00',
                                          color: 'white',
                                          fontSize: '12px',
                                          marginTop:'5px'
                                        }}
                                      >
                                         
                                        Resolve
                                      </Button>
                                    </Grid> */}

                              {row.id === Resolve ?(<>
                                <Box
                                  mb={2}
                                  sx={{
                                    marginBottom: '10px',

                                    marginTop: '15px',
                                    marginLeft: '10px'
                                  }}
                                >
                                  <Grid container columnSpacing={1}>
                                    <Grid item xs={12} style={{ fontWeight: 500 }}>
                                      <Typography style={{fontWeight:'bold',color:'black',fontSize:'14px',marginLeft:'15px'}}>
                                        Resolve Comments:
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{marginLeft:'15px'}}>
                                      <Controller
                                        name='resolvedComments'
                                        {...register('resolvedComments')}
                                        control={control}
                                        render={({ field }) => (
                                          <TextareaAutosize
                                            reset
                                            aria-label='minimum height'
                                            minRows={5}
                                            placeholder='Enter comments'
                                            style={{
                                              width: '100%',
                                              height: '100px',
                                              borderRadius: '8px',
                                              border: '2px solid #black',
                                              overflow: 'auto'
                                            }}
                                            // {...field}
                                            fullWidth
                                            
                                          />
                                        )}
                                      />
                                      <p style={{ color: 'red', fontSize: '14px' }}>
                                        {errors.resolvedComments?.message}
                                      </p>
                                    </Grid>
                                    <Grid item xs={3} >
                                      <Typography style={{fontWeight:'bold',color:'black',fontSize:'14px',marginLeft:'15px',marginTop:'-5px'}}>
                                        Resolve Date:
                                      </Typography>
                                    </Grid>

                                    <Grid item xs={6} style={{marginLeft:'15px'}}>
                                      <Controller
                                        name='completedDate'
                                        {...register('date')}
                                        control={control}
                                        render={({ field }) => (
                                          <TextField
                                            type='date'
                                            size='small'
                                            fullWidth
                                            {...field}
                                            placeholder='Enter resolve date'
                                            // value={completedDate}
                                            onChange={e => {
                                              setValue('resolvedDate', e.target.value)
                                              // setcompletedDate(e.target.value)
                                              // setcompeleterror(false)
                                            }}
                                          />
                                        )}
                                      />
                                      {/* {compeleterror ? (
                                              <p style={errorMesage}>Please select Compelete Date</p>
                                            ) : null} */}
                                            {errorMessage && (
                                      <p style={{ color: 'red' }}>Please Select Date</p>
                                    )}
                                     </Grid>
                                    <Grid item xs={8}></Grid>
                                    <Grid item xs={2}>
                                      <Button
                                       className='Button'
                                        onClick={handleSubmit(saveData)}
                                        variant='gradient'
                                      
                                        style={{
                                          marginTop: '15px',
                                          marginBottom: '10px',
                                          float: 'right',
                                          width: '87px',
                                          height: '28px',
                                          // backgroundColor: '#56CA00',
                                          color: 'white',
                                          fontSize: '12px',
                                           marginRight: '30px'
                                        }}
                                      // disabled={submitting}
                                      >
                                        <SaveOutlinedIcon
                                          fontSize='medium'
                                          style={{
                                            color: 'white',
                                            paddingRight: '5px',
                                            paddingBottom: '2px'
                                          }}
                                        ></SaveOutlinedIcon>
                                        Save
                                      </Button>
                                    </Grid>

                                    <Grid item xs={2}>
                                      <Button
                                       className='Button'
                                        onClick={() => {
                                          setResolve(null)
                                        }}
                                        variant='gradient'
                                        style={{
                                          marginTop: '15px',
                                          marginBottom: '10px',
                                          float: 'right',
                                          width: '87px',
                                          height: '28px',
                                          backgroundColor: '#56CA00',
                                          color: 'white',
                                          fontSize: '12px',
                                          marginRight: '18px'
                                        }}
                                      >
                                        <CancelOutlinedIcon
                                          fontSize='medium'
                                          style={{
                                            color: 'white',
                                            paddingRight: '5px',
                                            paddingBottom: '2px'
                                          }}
                                        ></CancelOutlinedIcon>
                                        Cancel
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </Box>
                              
                              </>):(<></>) } 
                </Grid>
              )}
                            {/* {row.id === id ? (
                            <>
                          
                            
                            </>):(<></>)} */}
                              </Grid>    
                     

                    </Grid>

                    </Paper>
                

                    )
                      })
                      : 'No Record Found'}
                </Paper>
                   
                 
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Grid container spacing={1}>
                     <Grid item xs={11}>
                     <Typography style={{fontSize:'16px',fontWeight:'bold',color:'black'}} >Alert-Message Requested/Pending Prior-auth/VOB</Typography>


                     </Grid>
                     <Grid item xs={1}>
                     
                <CachedOutlinedIcon
                onClick={event => {
                  applyRefreshapprove()
                 }}
               fontSize='medium'
               style={{
                 color: '#3C7F6B',
                //  marginRight: '-25px',
                 paddingRight: '0px',
                 paddingBottom: '1px'
               }}
             ></CachedOutlinedIcon>
          
                     </Grid>
                    </Grid>
                 
                   
                      <Paper
                      variant='outlined'
                      square
                      style={{
                        backgroundColor: '#F0F6F8',
                        borderRadius: '15px',
                        marginTop: '5px',
                        borderColor: '#377562',
                        maxWidth: '100%',
                        padding: '.5rem',
                        marginBottom: '0.3rem',
                        marginLeft: '0 .2rem',
                        height:'300px',
                        overflow:'auto',
                             /* Other styles */
                        scrollbarWidth: 'thin', // For Firefox
                        scrollbarColor: '#356F60 transparent', // thumb color and track color
                        '&::-webkit-scrollbar': {
                          width: 8, /* Width of vertical scrollbar */
                          height: 8, /* Height of horizontal scrollbar */
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: '#356F60', /* Color of the thumb */
                          borderRadius: 10,
                        },
                        '&::-webkit-scrollbar-track': {
                          backgroundColor: 'transparent', /* Color of the track */
                        },
                        /* Adjust the width for the horizontal scrollbar */
                        '&::-webkit-scrollbar-thumb:horizontal': {
                          width: 4, /* Width of horizontal scrollbar */
                        },
                       // zIndex: "10",
                       
                 
                      }}
                    >
                       <Grid container spacing={1}>
                       {/* <Grid item xs={1.3}>
                        <Typography style={{fontWeight:'bold',color:'black', marginTop: '4px',fontSize:'14px'}}>Type:</Typography>
  
                        </Grid> */}
                        {/* <Grid item xs={8}>
                        <Controller
                          name='type'
                          
                          control={control}
                          render={({ field }) => (
                            <ReactSelect
                              isMulti={true}
                              {...field}
                              value={includetype}
                              onChange={selectedOptions => {
                                // setValue('approvedpatient', selectedOptions)
                                setIncludetype(selectedOptions)

                                console.log('includetype', selectedOptions)
                              }}
                           
                              options={[
                                {
                                  label: 'All',
                                  value: 'All',
                                },
                                {
                                  label: 'Auth Request Submitted',
                                  value: 'Auth Request Submitted',
                                  
                                },
                                {label: 'Lomn' , value: 'Lomn', },
                              ]}
                              size='small'
                            />
                          )}
                        />
                      </Grid> */}
                        {/* <Grid item xs={2.7}>
                        <Button
                          className="Button"
                          variant="contained"
                          size="small"
                          onClick={onFilterSearchapporve}
                          style={{
                            float: 'right',
                            width: '80px',
                            height: '25px',
                            marginTop: '4px',
                            fontSize: '12px',
                            color: 'white',
                            // backgroundColor:'#004cab'
                          }}
                        >
                        <SearchOutlinedIcon
                          fontSize='small'
                          style={{ color: 'white', paddingBottom: '1px' }}
                        ></SearchOutlinedIcon>
                        Search
                      </Button>
                        </Grid> */}

                       </Grid>
                       {nonapprovedNotification != null && nonapprovedNotification.length > 0
                  ? nonapprovedNotification.map((row, index) => {
                    const isRowExpanded = expandedRowIndex === index;
                      return(
                        <Paper
                        variant='outlined'
                        square
                        style={{
                          backgroundColor:'white',
                          borderRadius: '10px',
                          marginTop: '5px',
                          borderColor: '#377562',
                          maxWidth: '100%',
                          padding: '.3rem',
                          marginBottom: '0.3rem',
                          marginLeft: '0 .2rem',
                        }}
                      >
                          <Grid container spacing={1}>
                          <Grid item xs={0.7}>
                          <Avatar sx={{ width: 22, height: 22,bgcolor: '#3C7F6B' }}>{row.patientName?.charAt(0).toUpperCase()}</Avatar>
                        </Grid>
                        <Grid item xs={10.3}>
              <Typography
                style={{ color: "black", fontSize: "14px" }}
                onClick={() => handleToggleExpansion1(index)}
              >
                <b>{row.patientName}'s </b> Authorization Request Submitted
              </Typography>
              <Typography style={{color:'green'}}> <b>{row.practiceName}</b> </Typography>


              
                          {/* {id1?(<>
                      <Typography style={{color:'black',fontSize:'14px'}} 
                      onClick={() => 
                        {
                          setid1(null)
                        }}
                                ><b>{row.patientName}'s</b>{row.description}</Typography>
                        
                        </>):(<>
                      <Typography style={{color:'black',fontSize:'14px'}} 
                       onClick={() => 
                        {
                        setid1(row.id)
                        }}
                        >
                          <b>{row.patientName}'s</b>{row.description}</Typography>
                        
                        </>)} */}

                      </Grid>
                        <Grid item xs={1} >
                        {isRowExpanded ? (<>
                              <ExpandLessIcon
                                onClick={() => {
                                  handleToggleExpansion1(index)

                                }}
                                fontSize='medium'
                                style={{
                                  color: 'black',
                                  paddingRight: '5px',
                                  paddingBottom: '2px'
                                }}
                                >
                                </ExpandLessIcon>
                        </>):(
                        <>
                               <ExpandMoreIcon
                               onClick={() => {
                                handleToggleExpansion1(index)

                              }}
                                fontSize='medium'
                                style={{
                                  color: 'black',
                                  paddingRight: '5px',
                                  paddingBottom: '2px'
                                }}
                                >
                                </ExpandMoreIcon>
                        </>)}
                            
                            </Grid>
                            {isRowExpanded  && (
                <Grid container spacing={1}>
                   {/* <Grid item xs={2}>
                        <Typography style={{fontWeight:'bold',color:'black',fontSize:'14px',marginLeft:'5px'}}>Comments:</Typography>
  
                        </Grid>
                        <Grid item xs={10}>
                        <Typography style={{color:'black',fontSize:'14px',marginLeft:'15px'}}>{row.comments}</Typography>
  
                        </Grid> */}
                       
                           
                       
                        <Grid item xs={10}>
                        <Button
                              style={{
                                backgroundColor: "#E87426",
                                color: 'black',
                                float: 'right',
                                width: '200px',
                                height: '28px',
                                marginTop:'5px',
                                fontWeight:'bold', 
                                marginRight:'30px'
  
  
                              }}
                              size='small'
                              onClick={() => {
                                // handleOpens()
                                setauthId2(row.authId)
                                setauthIdcpt(row.authCPTId)
                               setPriorPracticeID(row.practiceId)
                              setpatinetIdopenauth1(row.patientId)
                              setpatinetnameopenauth1(row.patientName)
                              handleluxbillingpending()
                               }}
                             >
                                
                              <Typography
                                fontSize="small"
                                style={{ color: "black", }}
                              >Open Auth Request</Typography>
  
                            </Button>
  
                        </Grid>
                        <Grid item xs={2} >
                                      {/* <Button
                                       className='Button'
                                        onClick={() => {
                                          setselectedRow(row.id)
                                          setselectedindex(index)
                                          setResolve(row.id)
                                        }}
                                        variant='gradient'
                                        style={{
                                          marginBottom: '10px',
                                          float: 'right',
                                          width: '87px',
                                          height: '28px',
                                          backgroundColor: '#56CA00',
                                          color: 'white',
                                          fontSize: '12px',
                                          marginTop:'5px'
                                        }}
                                      >
                                        
                                        Resolve
                                      </Button> */}
                                    </Grid>

                              {row.id === Resolve ?(<>
                                <Box
                                  mb={2}
                                  sx={{
                                    marginBottom: '10px',

                                    marginTop: '15px',
                                    marginLeft: '10px'
                                  }}
                                >
                                  <Grid container columnSpacing={1}>
                                    <Grid item xs={12} style={{ fontWeight: 500 }}>
                                      <Typography style={{fontWeight:'bold',color:'black',fontSize:'14px',marginLeft:'5px'}}>
                                        Resolve Comments:
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{marginLeft:'5px'}}>
                                      <Controller
                                        name='resolvedComments'
                                        {...register('resolvedComments')}
                                        control={control}
                                        render={({ field }) => (
                                          <TextareaAutosize
                                            reset
                                            aria-label='minimum height'
                                            minRows={1}
                                            placeholder='Enter comments'
                                            style={{
                                              width: '100%',
                                              height: '100px',
                                              borderRadius: '8px',
                                              border: '2px solid #black',
                                              overflow: 'auto'
                                            }}
                                            onChange={e => {
                                              setValue('resolvedComments', e.target.value)
                                             
                                            }}
                                            fullWidth
                                            
                                          />
                                        )}
                                      />
                                      <p style={{ color: 'red', fontSize: '14px' }}>
                                        {errors.resolvedComments?.message}
                                      </p>
                                    </Grid>
                                    <Grid item xs={3} >
                                      <Typography style={{fontWeight:'bold',color:'black',fontSize:'14px',marginLeft:'5px',marginTop:'-5px'}}>
                                        Resolve Date:
                                      </Typography>
                                    </Grid>

                                    <Grid item xs={9} style={{marginTop:'-5px'}}>
                                      <Controller
                                        name='completedDate'
                                        {...register('date')}
                                        control={control}
                                        render={({ field }) => (
                                          <TextField
                                            type='date'
                                            size='small'
                                            fullWidth
                                            {...field}
                                            placeholder='Enter resolve date'
                                        
                                            onChange={e => {
                                              setValue('resolvedDate', e.target.value)
                                             
                                            }}
                                          />
                                        )}
                                      />
                                      {errorMessage && (
                                                <p style={{ color: 'red' }}>Please Select Date</p>
                                              )}
                                    </Grid>
                                    <Grid item xs={8}></Grid>
                                    <Grid item xs={2}>
                                      <Button
                                       className='Button'
                                        onClick={handleSubmit(saveData)}
                                        variant='gradient'
                                      
                                        style={{
                                          marginTop: '15px',
                                          marginBottom: '10px',
                                          float: 'right',
                                          width: '87px',
                                          height: '28px',
                                         
                                          color: 'white',
                                          fontSize: '12px',
                                           marginRight: '50px'
                                        }}
                                  
                                      >
                                        <SaveOutlinedIcon
                                          fontSize='medium'
                                          style={{
                                            color: 'white',
                                            paddingRight: '5px',
                                            paddingBottom: '2px'
                                          }}
                                        ></SaveOutlinedIcon>
                                        Save
                                      </Button>
                                    </Grid>

                                    <Grid item xs={2}>
                                      <Button
                                       className='Button'
                                        onClick={() => {
                                          setResolve(null)
                                        }}
                                        variant='gradient'
                                        style={{
                                          marginTop: '15px',
                                          marginBottom: '10px',
                                          float: 'right',
                                          width: '87px',
                                          height: '28px',
                                          backgroundColor: '#56CA00',
                                          color: 'white',
                                          fontSize: '12px',
                                          marginRight: '18px'
                                        }}
                                      >
                                        <CancelOutlinedIcon
                                          fontSize='medium'
                                          style={{
                                            color: 'white',
                                            paddingRight: '5px',
                                            paddingBottom: '2px'
                                          }}
                                        ></CancelOutlinedIcon>
                                        Cancel
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </Box>
                              
                              </>):(<></>) } 
                </Grid>
              )}
                              {/* <Grid container style={{ fontWeight: 500 }}>

                              {row.id === id1 ? (<>
                               
                       
                              
                              </>):(<></>)}
                                </Grid>     */}
                       
  
                      </Grid>
  
                      </Paper>

                    )
                      })
                      : 'No Record Found'}
                      </Paper>
                   
                 
                  </Grid>
                 {/* //////// Claim Submission ///// */}
                 {/* //////Claim Submission /// */}
                 <Grid container spacing={1} style={{ marginTop: '5px' }}>
                 <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Grid container spacing={1}>
                     <Grid item xs={11}>
                     <Typography style={{fontSize:'16px',fontWeight:'bold',color:'black'}} >Alert-Message Completed Claims </Typography>

                     </Grid>
                     <Grid item xs={1}>
                     
                <CachedOutlinedIcon
                onClick={event => {
                  applyRefreshclaim()
                 }}
               fontSize='medium'
               style={{
                 color: '#3C7F6B',
                //  marginRight: '-25px',
                 paddingRight: '0px',
                 paddingBottom: '1px'
               }}
             ></CachedOutlinedIcon>
          
                     </Grid>
                    </Grid>
                       
                   
                      <Paper
                      variant='outlined'
                      square
                      style={{
                        backgroundColor: '#F0F6F8',
                        borderRadius: '15px',
                        marginTop: '3px',
                        borderColor: '#377562',
                        maxWidth: '100%',
                        padding: '.5rem',
                        marginBottom: '0.5rem',
                        marginLeft: '0 .2rem',
                        height:300,
                        overflow: "auto",
                        /* Other styles */
                        scrollbarWidth: 'thin', // For Firefox
                        scrollbarColor: '#356F60 transparent', // thumb color and track color
                        '&::-webkit-scrollbar': {
                          width: 8, /* Width of vertical scrollbar */
                          height: 8, /* Height of horizontal scrollbar */
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: '#356F60', /* Color of the thumb */
                          borderRadius: 10,
                        },
                        '&::-webkit-scrollbar-track': {
                          backgroundColor: 'transparent', /* Color of the track */
                        },
                        /* Adjust the width for the horizontal scrollbar */
                        '&::-webkit-scrollbar-thumb:horizontal': {
                          width: 4, /* Width of horizontal scrollbar */
                        },
                       // zIndex: "10",
                       
                      }}
                    >
                       {claimNotification1 != null && claimNotification1.length > 0
                  ? claimNotification1.map((row, index) => {
                      return(
                        <Paper
                      variant='outlined'
                      square
                      style={{
                        backgroundColor:'white',
                        borderRadius: '10px',
                        // marginTop: '3px',
                        borderColor: '#377562',
                        maxWidth: '100%',
                        padding: '.3rem',
                        marginBottom: '0.3rem',
                        marginLeft: '0 .2rem',
                      }}
                    >
                        <Grid container spacing={1}>
                        {/* <Grid item xs={2}>
                      <Typography style={{color:'black',fontWeight:'bold'}}>{row.patientName}'S</Typography>

                      </Grid> */}
                        <Grid item xs={0.7}>
                          <Avatar sx={{ width: 22, height: 22,bgcolor: '#3C7F6B' }}>{row.patientName?.charAt(0).toUpperCase()}</Avatar>
                        </Grid>
                      <Grid item xs={10.3}>
                      {/* <Typography
                  style={{ color: 'black', fontSize: '14px' }}
                  onClick={() => handleToggleExpansion(row.id)} 
                >
                  <b>{row.patientName}'s</b> {row.description}
                </Typography> */}
                      {expandedRowIdclaim1 === row.id ? (<>
                     
                                 <Grid container >
                        <Grid item xs={4}>
                        <Typography style={{color:'black',fontSize:'14px'}} 
                      onClick={() => 
                        {
                          handleToggleExpansionclaim1(null)
                        }}
                                ><b>{row.patientName}'s</b></Typography>


                        </Grid>
                        <Grid item xs={4}>
                        <Typography style={{color:'black',fontSize:'14px',marginLeft:'15px',marginTop:'1px'}}><b>ID:</b>{row.id}</Typography>

                        </Grid>
                        <Grid item xs={4}>
                        <Typography style={{color:'black',fontSize:'14px',marginLeft:'15px',marginTop:'1px'}}><b>DOS:</b>{row.dos}</Typography>

                        </Grid>
                        <Grid item xs={12}>

                        <Typography style={{color:'green'}}> <b>{row.practiceName}</b> </Typography>


                        </Grid>
                      </Grid>
                        
                        </>):(<>
                          <Grid container >
                        <Grid item xs={4}>
                        <Typography style={{color:'black',fontSize:'14px'}} 
                      onClick={() => 
                        {
                          handleToggleExpansionclaim1(null)
                        }}
                                ><b>{row.patientName}'s</b></Typography>
                        </Grid>
                        <Grid item xs={4}>
                        <Typography style={{color:'black',fontSize:'14px',marginLeft:'15px',marginTop:'1px'}}><b>ID:</b>{row.id}</Typography>

                        </Grid>
                        <Grid item xs={4}>
                        <Typography style={{color:'black',fontSize:'14px',marginLeft:'15px',marginTop:'1px'}}><b>DOS:</b>{row.dos}</Typography>

                        </Grid>
                        <Grid item xs={12}>

                        <Typography style={{color:'green'}}> <b>{row.practiceName}</b> </Typography>


</Grid>
                      </Grid>
                        
                        </>)}

                      </Grid>
                      
                      <Grid item xs={1} >
                      {expandedRowIdclaim1 === row.id ? (<>
                          
                              <ExpandLessIcon
                                onClick={() => handleToggleExpansionclaim1(row.id)}
                                fontSize='medium'
                                style={{
                                  color: 'black',
                                  paddingRight: '5px',
                                  paddingBottom: '2px'
                                }}
                                >
                                </ExpandLessIcon>
                        </>):(
                        <>
                               <ExpandMoreIcon
                              onClick={() => handleToggleExpansionclaim1(row.id)} 
                                fontSize='medium'
                                style={{
                                  color: 'black',
                                  paddingRight: '5px',
                                  paddingBottom: '2px'
                                }}
                                >
                                </ExpandMoreIcon>
                        </>)}
                            
                            </Grid>
                           
                            <Grid container spacing={1} >
                            {expandedRowIdclaim1 === row.id && (
                <Grid container spacing={1} style={{marginTop:'10px'}}>
                <Grid item xs={3}>
                     <Typography style={{fontWeight:'bold',color:'black',fontSize:'14px',marginLeft:'13px',marginTop:'5px'}}>Physician Name:</Typography>

                     </Grid>
                     <Grid item xs={3}>
                     <Typography style={{color:'black',fontSize:'14px',marginTop:'5px'}}>{row.attendingPhysicianName}</Typography>

                     </Grid>
                    
                     <Grid item xs={2.5}>
                     <Typography style={{fontWeight:'bold',color:'black',fontSize:'14px',marginTop:'5px'}}>Auth Number:</Typography>

                     </Grid>
                     <Grid item xs={3.5} >
                     <Typography style={{color:'black',fontSize:'14px',marginTop:'5px'}}>{row.authNumber}</Typography>

                     </Grid>
                   
                     <Grid item xs={12}>
                     <Button
                           style={{
                             backgroundColor: 'green',
                             color: 'white',
                             float: 'right',
                             width: '120px',
                             height: '28px',
                             marginTop:'5px',
                             // marginRight:'25px'


                           }}
                           size='small'
                           onClick={() => {
                             setclaimId(row.id)
                             setpatinetIdopenclaim(row.patientId)
                             setpatinetnameopencalim(row.patientName)
                            handleclaimopen()
                            }}
                          >
                             
                           <Typography
                             fontSize="small"
                             style={{ color: "#fff", }}
                           > Open Claim </Typography>

                         </Button>

                     </Grid>
                     {/* <Grid item xs={2} >
                                     <Button
                                      className='Button'
                                       onClick={() => {
                                         setselectedRow(row.id)
                                         setselectedindex(index)
                                         setResolve(row.id)
                                       }}
                                       variant='gradient'
                                       style={{
                                         marginBottom: '10px',
                                         float: 'right',
                                         width: '87px',
                                         height: '28px',
                                         backgroundColor: '#56CA00',
                                         color: 'white',
                                         fontSize: '12px',
                                         marginTop:'5px'
                                       }}
                                     >
                                      
                                       Resolve
                                     </Button>
                                   </Grid> */}

                             {/* {row.id === Resolve ?(<>
                               <Box
                                 mb={2}
                                 sx={{
                                   marginBottom: '10px',

                                   marginTop: '15px',
                                   marginLeft: '10px'
                                 }}
                               >
                                 <Grid container columnSpacing={1}>
                                   <Grid item xs={12} style={{ fontWeight: 500 }}>
                                     <Typography style={{fontWeight:'bold',color:'black',fontSize:'14px',marginLeft:'15px'}}>
                                       Ressolve Comments:
                                     </Typography>
                                   </Grid>
                                   <Grid item xs={12} style={{marginLeft:'15px'}}>
                                     <Controller
                                       name='resolvedComments'
                                       {...register('resolvedComments')}
                                       control={control}
                                       render={({ field }) => (
                                         <TextareaAutosize
                                           reset
                                           aria-label='minimum height'
                                           minRows={1}
                                           placeholder='Enter comments'
                                           style={{
                                             width: '100%',
                                             height: '100px',
                                             borderRadius: '8px',
                                             border: '2px solid #black',
                                             overflow: 'auto'
                                           }}
                                     
                                           fullWidth
                                           
                                         />
                                       )}
                                     />
                                     <p style={{ color: 'red', fontSize: '14px' }}>
                                       {errors.resolvedComments?.message}
                                     </p>
                                   </Grid>
                                   <Grid item xs={3} >
                                     <Typography style={{fontWeight:'bold',color:'black',fontSize:'14px',marginLeft:'15px',marginTop:'-5px'}}>
                                       Resolve Date:
                                     </Typography>
                                   </Grid>

                                   <Grid item xs={6} style={{marginLeft:'15px'}}>
                                     <Controller
                                       name='completedDate'
                                       {...register('date')}
                                       control={control}
                                       render={({ field }) => (
                                         <TextField
                                           type='date'
                                           size='small'
                                           fullWidth
                                           {...field}
                                           placeholder='Enter resolve date'
                                         
                                           onChange={e => {
                                             setValue('resolvedDate', e.target.value)
                                           
                                           }}
                                         />
                                       )}
                                     />
                                  
                                   </Grid>
                                   <Grid item xs={8}></Grid>
                                   <Grid item xs={2}>
                                     <Button
                                      className='Button'
                                       onClick={handleSubmit(saveData)}
                                       variant='gradient'
                                     
                                       style={{
                                         marginTop: '15px',
                                         marginBottom: '10px',
                                         float: 'right',
                                         width: '87px',
                                         height: '28px',
                                   
                                         color: 'white',
                                         fontSize: '12px',
                                          marginRight: '30px'
                                       }}
                                  
                                     >
                                       <SaveOutlinedIcon
                                         fontSize='medium'
                                         style={{
                                           color: 'white',
                                           paddingRight: '5px',
                                           paddingBottom: '2px'
                                         }}
                                       ></SaveOutlinedIcon>
                                       Save
                                     </Button>
                                   </Grid>

                                   <Grid item xs={2}>
                                     <Button
                                      className='Button'
                                       onClick={() => {
                                         setResolve(null)
                                       }}
                                       variant='gradient'
                                       style={{
                                         marginTop: '15px',
                                         marginBottom: '10px',
                                         float: 'right',
                                         width: '87px',
                                         height: '28px',
                                         backgroundColor: '#56CA00',
                                         color: 'white',
                                         fontSize: '12px',
                                         marginRight: '18px'
                                       }}
                                     >
                                       <CancelOutlinedIcon
                                         fontSize='medium'
                                         style={{
                                           color: 'white',
                                           paddingRight: '5px',
                                           paddingBottom: '2px'
                                         }}
                                       ></CancelOutlinedIcon>
                                       Cancel
                                     </Button>
                                   </Grid>
                                 </Grid>
                               </Box>
                             
                             </>):(<></>) }  */}
               </Grid>
              )}
                            {/* {row.id === id ? (
                            <>
                          
                            
                            </>):(<></>)} */}
                              </Grid>    
                     

                    </Grid>

                    </Paper>
                

                    )
                      })
                      : 'No Record Found'}
                </Paper>
                   
                 
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Grid container spacing={1}>
                     <Grid item xs={11}>
                     <Typography style={{fontSize:'16px',fontWeight:'bold',color:'black'}} >Alert-Message Pending Claims </Typography>

                     </Grid>
                     <Grid item xs={1}>
                     
                <CachedOutlinedIcon
                onClick={event => {
                  applyRefreshclaim()
                 }}
               fontSize='medium'
               style={{
                 color: '#3C7F6B',
                //  marginRight: '-25px',
                 paddingRight: '0px',
                 paddingBottom: '1px'
               }}
             ></CachedOutlinedIcon>
          
                     </Grid>
                    </Grid>
                       
                   
                      <Paper
                      variant='outlined'
                      square
                      style={{
                        backgroundColor: '#F0F6F8',
                        borderRadius: '15px',
                        marginTop: '3px',
                        borderColor: '#377562',
                        maxWidth: '100%',
                        padding: '.5rem',
                        marginBottom: '0.5rem',
                        marginLeft: '0 .2rem',
                        height:300,
                        overflow: "auto",
                        /* Other styles */
                        scrollbarWidth: 'thin', // For Firefox
                        scrollbarColor: '#356F60 transparent', // thumb color and track color
                        '&::-webkit-scrollbar': {
                          width: 8, /* Width of vertical scrollbar */
                          height: 8, /* Height of horizontal scrollbar */
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: '#356F60', /* Color of the thumb */
                          borderRadius: 10,
                        },
                        '&::-webkit-scrollbar-track': {
                          backgroundColor: 'transparent', /* Color of the track */
                        },
                        /* Adjust the width for the horizontal scrollbar */
                        '&::-webkit-scrollbar-thumb:horizontal': {
                          width: 4, /* Width of horizontal scrollbar */
                        },
                       // zIndex: "10",
                       
                      }}
                    >
                       {claimNotification != null && claimNotification.length > 0
                  ? claimNotification.map((row, index) => {
                      return(
                        <Paper
                      variant='outlined'
                      square
                      style={{
                        backgroundColor:'white',
                        borderRadius: '10px',
                        // marginTop: '3px',
                        borderColor: '#377562',
                        maxWidth: '100%',
                        padding: '.3rem',
                        marginBottom: '0.3rem',
                        marginLeft: '0 .2rem',
                      }}
                    >
                        <Grid container spacing={1}>
                        {/* <Grid item xs={2}>
                      <Typography style={{color:'black',fontWeight:'bold'}}>{row.patientName}'S</Typography>

                      </Grid> */}
                        <Grid item xs={0.7}>
                          <Avatar sx={{ width: 22, height: 22,bgcolor: '#3C7F6B' }}>{row.patientName?.charAt(0).toUpperCase()}</Avatar>
                        </Grid>
                      <Grid item xs={10.3}>
                      {/* <Typography
                  style={{ color: 'black', fontSize: '14px' }}
                  onClick={() => handleToggleExpansion(row.id)} 
                >
                  <b>{row.patientName}'s</b> {row.description}
                </Typography> */}
                      {expandedRowIdclaim === row.id ? (<>
                      <Grid container >
                        <Grid item xs={4}>
                        <Typography style={{color:'black',fontSize:'14px'}} 
                      onClick={() => 
                        {
                          handleToggleExpansionclaim(null)
                        }}
                                ><b>{row.patientName}'s</b></Typography>
                        </Grid>
                        <Grid item xs={4}>
                        <Typography style={{color:'black',fontSize:'14px',marginLeft:'15px',marginTop:'1px'}}><b>ID:</b>{row.id}</Typography>

                        </Grid>
                        <Grid item xs={4}>
                        <Typography style={{color:'black',fontSize:'14px',marginLeft:'15px',marginTop:'1px'}}><b>DOS:</b>{row.dos}</Typography>

                        </Grid>
                        <Grid item xs={12}>

                        <Typography style={{color:'green'}}> <b>{row.practiceName}</b> </Typography>


</Grid>
                      </Grid>
                     

                        
                        </>):(<>
                          <Grid container >
                        <Grid item xs={4}>
                        <Typography style={{color:'black',fontSize:'14px'}} 
                      onClick={() => 
                        {
                          handleToggleExpansionclaim(null)
                        }}
                                ><b>{row.patientName}'s</b></Typography>
                        </Grid>
                        <Grid item xs={4}>
                        <Typography style={{color:'black',fontSize:'14px',marginLeft:'15px',marginTop:'1px'}}><b>ID:</b>{row.id}</Typography>

                        </Grid>
                        <Grid item xs={4}>
                        <Typography style={{color:'black',fontSize:'14px',marginLeft:'15px',marginTop:'1px'}}><b>DOS:</b>{row.dos}</Typography>

                        </Grid>
                        <Grid item xs={12}>

                        <Typography style={{color:'green'}}> <b>{row.practiceName}</b> </Typography>


</Grid>
                      </Grid>
                        
                        </>)}

                      </Grid>
                      
                      <Grid item xs={1} >
                      {expandedRowIdclaim === row.id ? (<>
                          
                              <ExpandLessIcon
                                onClick={() => handleToggleExpansionclaim(row.id)}
                                fontSize='medium'
                                style={{
                                  color: 'black',
                                  paddingRight: '5px',
                                  paddingBottom: '2px'
                                }}
                                >
                                </ExpandLessIcon>
                        </>):(
                        <>
                               <ExpandMoreIcon
                              onClick={() => handleToggleExpansionclaim(row.id)} 
                                fontSize='medium'
                                style={{
                                  color: 'black',
                                  paddingRight: '5px',
                                  paddingBottom: '2px'
                                }}
                                >
                                </ExpandMoreIcon>
                        </>)}
                            
                            </Grid>
                           
                            <Grid container spacing={1} >
                            {expandedRowIdclaim === row.id && (
                <Grid container spacing={1} style={{marginTop:'10px'}}>
                 <Grid item xs={3.1}>
                      <Typography style={{fontWeight:'bold',color:'black',fontSize:'14px',marginLeft:'15px',marginTop:'5px'}}>Physician Name:</Typography>

                      </Grid>
                      <Grid item xs={2.6} >
                      <Typography style={{color:'black',fontSize:'14px',marginLeft:'15px',marginTop:'5px'}}>{row.attendingPhysicianName}</Typography>

                      </Grid>
                     
                      <Grid item xs={2.9}>
                      <Typography style={{fontWeight:'bold',color:'black',fontSize:'14px',marginLeft:'15px',marginTop:'5px'}}>Auth Number:</Typography>

                      </Grid>
                      <Grid item xs={3.3} >
                      <Typography style={{color:'black',fontSize:'14px',marginLeft:'15px',marginTop:'5px'}}>{row.authNumber}</Typography>

                      </Grid>
                    
                      <Grid item xs={12}>
                      <Button
                            style={{
                              backgroundColor: 'green',
                              color: 'white',
                              float: 'right',
                              width: '120px',
                              height: '28px',
                              marginTop:'5px',
                              // marginRight:'25px'


                            }}
                            size='small'
                            onClick={() => {
                              setclaimId(row.id)
                              setpatinetIdopenclaim(row.patientId)
                              setpatinetnameopencalim(row.patientName)
                             handleclaimopen()
                             }}
                           >
                              
                            <Typography
                              fontSize="small"
                              style={{ color: "#fff", }}
                            > Open Claim  </Typography>

                          </Button>

                      </Grid>
                      {/* <Grid item xs={2} >
                                      <Button
                                       className='Button'
                                        onClick={() => {
                                          setselectedRow(row.id)
                                          setselectedindex(index)
                                          setResolve(row.id)
                                        }}
                                        variant='gradient'
                                        style={{
                                          marginBottom: '10px',
                                          float: 'right',
                                          width: '87px',
                                          height: '28px',
                                          backgroundColor: '#56CA00',
                                          color: 'white',
                                          fontSize: '12px',
                                          marginTop:'5px'
                                        }}
                                      >
                                       
                                        Resolve
                                      </Button>
                                    </Grid> */}

                              {/* {row.id === Resolve ?(<>
                                <Box
                                  mb={2}
                                  sx={{
                                    marginBottom: '10px',

                                    marginTop: '15px',
                                    marginLeft: '10px'
                                  }}
                                >
                                  <Grid container columnSpacing={1}>
                                    <Grid item xs={12} style={{ fontWeight: 500 }}>
                                      <Typography style={{fontWeight:'bold',color:'black',fontSize:'14px',marginLeft:'15px'}}>
                                        Ressolve Comments:
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{marginLeft:'15px'}}>
                                      <Controller
                                        name='resolvedComments'
                                        {...register('resolvedComments')}
                                        control={control}
                                        render={({ field }) => (
                                          <TextareaAutosize
                                            reset
                                            aria-label='minimum height'
                                            minRows={1}
                                            placeholder='Enter comments'
                                            style={{
                                              width: '100%',
                                              height: '100px',
                                              borderRadius: '8px',
                                              border: '2px solid #black',
                                              overflow: 'auto'
                                            }}
                                      
                                            fullWidth
                                            
                                          />
                                        )}
                                      />
                                      <p style={{ color: 'red', fontSize: '14px' }}>
                                        {errors.resolvedComments?.message}
                                      </p>
                                    </Grid>
                                    <Grid item xs={3} >
                                      <Typography style={{fontWeight:'bold',color:'black',fontSize:'14px',marginLeft:'15px',marginTop:'-5px'}}>
                                        Resolve Date:
                                      </Typography>
                                    </Grid>

                                    <Grid item xs={6} style={{marginLeft:'15px'}}>
                                      <Controller
                                        name='completedDate'
                                        {...register('date')}
                                        control={control}
                                        render={({ field }) => (
                                          <TextField
                                            type='date'
                                            size='small'
                                            fullWidth
                                            {...field}
                                            placeholder='Enter resolve date'
                                          
                                            onChange={e => {
                                              setValue('resolvedDate', e.target.value)
                                            
                                            }}
                                          />
                                        )}
                                      />
                                   
                                    </Grid>
                                    <Grid item xs={8}></Grid>
                                    <Grid item xs={2}>
                                      <Button
                                       className='Button'
                                        onClick={handleSubmit(saveData)}
                                        variant='gradient'
                                      
                                        style={{
                                          marginTop: '15px',
                                          marginBottom: '10px',
                                          float: 'right',
                                          width: '87px',
                                          height: '28px',
                                    
                                          color: 'white',
                                          fontSize: '12px',
                                           marginRight: '30px'
                                        }}
                                   
                                      >
                                        <SaveOutlinedIcon
                                          fontSize='medium'
                                          style={{
                                            color: 'white',
                                            paddingRight: '5px',
                                            paddingBottom: '2px'
                                          }}
                                        ></SaveOutlinedIcon>
                                        Save
                                      </Button>
                                    </Grid>

                                    <Grid item xs={2}>
                                      <Button
                                       className='Button'
                                        onClick={() => {
                                          setResolve(null)
                                        }}
                                        variant='gradient'
                                        style={{
                                          marginTop: '15px',
                                          marginBottom: '10px',
                                          float: 'right',
                                          width: '87px',
                                          height: '28px',
                                          backgroundColor: '#56CA00',
                                          color: 'white',
                                          fontSize: '12px',
                                          marginRight: '18px'
                                        }}
                                      >
                                        <CancelOutlinedIcon
                                          fontSize='medium'
                                          style={{
                                            color: 'white',
                                            paddingRight: '5px',
                                            paddingBottom: '2px'
                                          }}
                                        ></CancelOutlinedIcon>
                                        Cancel
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </Box>
                              
                              </>):(<></>) }  */}
                </Grid>
              )}
                            {/* {row.id === id ? (
                            <>
                          
                            
                            </>):(<></>)} */}
                              </Grid>    
                     

                    </Grid>

                    </Paper>
                

                    )
                      })
                      : 'No Record Found'}
                </Paper>
                   
                 
                  </Grid>
                 
                  </Grid>

                  <Grid container spacing={1} style={{marginTop:'5px'}}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Grid container spacing={1}>
                     <Grid item xs={11}>
                     <Typography style={{fontSize:'16px',fontWeight:'bold',color:'black'}} >Alert-Message Paid Claims </Typography>

                     </Grid>
                     <Grid item xs={1}>
                     
                <CachedOutlinedIcon
                onClick={event => {
                  applyRefreshclaim()
                 }}
               fontSize='medium'
               style={{
                 color: '#3C7F6B',
                //  marginRight: '-25px',
                 paddingRight: '0px',
                 paddingBottom: '1px'
               }}
             ></CachedOutlinedIcon>
          
                     </Grid>
                    </Grid>
                       
                   
                      <Paper
                      variant='outlined'
                      square
                      style={{
                        backgroundColor: '#F0F6F8',
                        borderRadius: '15px',
                        marginTop: '3px',
                        borderColor: '#377562',
                        maxWidth: '100%',
                        padding: '.5rem',
                        marginBottom: '0.5rem',
                        marginLeft: '0 .2rem',
                        height:300,
                        overflow: "auto",
                        /* Other styles */
                        scrollbarWidth: 'thin', // For Firefox
                        scrollbarColor: '#356F60 transparent', // thumb color and track color
                        '&::-webkit-scrollbar': {
                          width: 8, /* Width of vertical scrollbar */
                          height: 8, /* Height of horizontal scrollbar */
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: '#356F60', /* Color of the thumb */
                          borderRadius: 10,
                        },
                        '&::-webkit-scrollbar-track': {
                          backgroundColor: 'transparent', /* Color of the track */
                        },
                        /* Adjust the width for the horizontal scrollbar */
                        '&::-webkit-scrollbar-thumb:horizontal': {
                          width: 4, /* Width of horizontal scrollbar */
                        },
                       // zIndex: "10",
                       
                      }}
                    >
                       {claimNotificationpayment != null && claimNotificationpayment.length > 0
                  ? claimNotificationpayment.map((row, index) => {
                      return(
                        <Paper
                      variant='outlined'
                      square
                      style={{
                        backgroundColor:'white',
                        borderRadius: '10px',
                        // marginTop: '3px',
                        borderColor: '#377562',
                        maxWidth: '100%',
                        padding: '.3rem',
                        marginBottom: '0.3rem',
                        marginLeft: '0 .2rem',
                      }}
                    >
                        <Grid container spacing={1}>
                        {/* <Grid item xs={2}>
                      <Typography style={{color:'black',fontWeight:'bold'}}>{row.patientName}'S</Typography>

                      </Grid> */}
                        <Grid item xs={0.7}>
                          <Avatar sx={{ width: 22, height: 22,bgcolor: '#3C7F6B' }}>{row.patientName?.charAt(0).toUpperCase()}</Avatar>
                        </Grid>
                      <Grid item xs={10.3}>
                      {/* <Typography
                  style={{ color: 'black', fontSize: '14px' }}
                  onClick={() => handleToggleExpansion(row.id)} 
                >
                  <b>{row.patientName}'s</b> {row.description}
                </Typography> */}
                      {expandedRowIdclaim2 === row.id ? (<>
                      <Grid container >
                        <Grid item xs={4}>
                        <Typography style={{color:'black',fontSize:'14px'}} 
                      onClick={() => 
                        {
                          handleToggleExpansionclaim2(null)
                        }}
                                ><b>{row.patientName}'s</b></Typography>
                        </Grid>
                        <Grid item xs={4}>
                        <Typography style={{color:'black',fontSize:'14px',marginLeft:'15px',marginTop:'1px'}}><b>ID:</b>{row.id}</Typography>

                        </Grid>
                        <Grid item xs={4}>
                        <Typography style={{color:'black',fontSize:'14px',marginLeft:'15px',marginTop:'1px'}}><b>DOS:</b>{row.dos}</Typography>

                        </Grid>
                        <Grid item xs={12}>

                        <Typography style={{color:'green'}}> <b>{row.practiceName}</b> </Typography>


</Grid>
                      </Grid>
                     

                        
                        </>):(<>
                          <Grid container >
                        <Grid item xs={4}>
                        <Typography style={{color:'black',fontSize:'14px'}} 
                      onClick={() => 
                        {
                          handleToggleExpansionclaim2(null)
                        }}
                                ><b>{row.patientName}'s</b></Typography>
                        </Grid>
                        <Grid item xs={4}>
                        <Typography style={{color:'black',fontSize:'14px',marginLeft:'15px',marginTop:'1px'}}><b>ID:</b>{row.id}</Typography>

                        </Grid>
                        <Grid item xs={4}>
                        <Typography style={{color:'black',fontSize:'14px',marginLeft:'15px',marginTop:'1px'}}><b>DOS:</b>{row.dos}</Typography>

                        </Grid>
                        <Grid item xs={12}>

<Typography style={{color:'green'}}> <b>{row.practiceName}</b> </Typography>


</Grid>
                      </Grid>
                        
                        </>)}

                      </Grid>
                      
                      <Grid item xs={1} >
                      {expandedRowIdclaim2 === row.id ? (<>
                          
                              <ExpandLessIcon
                                onClick={() => handleToggleExpansionclaim2(row.id)}
                                fontSize='medium'
                                style={{
                                  color: 'black',
                                  paddingRight: '5px',
                                  paddingBottom: '2px'
                                }}
                                >
                                </ExpandLessIcon>
                        </>):(
                        <>
                               <ExpandMoreIcon
                              onClick={() => handleToggleExpansionclaim2(row.id)} 
                                fontSize='medium'
                                style={{
                                  color: 'black',
                                  paddingRight: '5px',
                                  paddingBottom: '2px'
                                }}
                                >
                                </ExpandMoreIcon>
                        </>)}
                            
                            </Grid>
                           
                            <Grid container spacing={1} >
                            {expandedRowIdclaim2 === row.id && (
                <Grid container spacing={1} style={{marginTop:'10px'}}>
                 <Grid item xs={3.5}>
                      <Typography style={{fontWeight:'bold',color:'black',fontSize:'14px',marginLeft:'15px',marginTop:'5px'}}>Physician Name:</Typography>

                      </Grid>
                      <Grid item xs={3.5}>
                      <Typography style={{color:'black',fontSize:'14px',marginLeft:'15px',marginTop:'5px'}}>{row.attendingPhysicianName}</Typography>

                      </Grid>
                     
                      <Grid item xs={3}>
                      <Typography style={{fontWeight:'bold',color:'black',fontSize:'14px',marginLeft:'15px',marginTop:'5px'}}>Auth Number:</Typography>

                      </Grid>
                      <Grid item xs={2}>
                      <Typography style={{color:'black',fontSize:'14px',marginLeft:'15px',marginTop:'5px'}}>{row.authNumber}</Typography>

                      </Grid>
                    
                      <Grid item xs={12}>
                      <Button
                            style={{
                              backgroundColor: 'green',
                              color: 'white',
                              float: 'right',
                              width: '120px',
                              height: '28px',
                              marginTop:'5px',
                              // marginRight:'25px'


                            }}
                            size='small'
                            onClick={() => {
                              setclaimId(row.id)
                              setpatinetIdopenclaim(row.patientId)
                              setpatinetnameopencalim(row.patientName)
                             handleclaimopen()
                             }}
                           >
                              
                            <Typography
                              fontSize="small"
                              style={{ color: "#fff", }}
                            > Open Claim  </Typography>

                          </Button>

                      </Grid>
                     
                </Grid>
              )}
                          
                              </Grid>    
                     

                    </Grid>

                    </Paper>
                

                    )
                      })
                      : 'No Record Found'}
                </Paper>
                   
                 
                  </Grid>


                  </Grid>
                  </Grid>

                 
                 

                </Container>
                  
              </>
            )}
 {/* Render your modal component */}
 {isModalOpen && (
                              <Modal
                                open={isModalOpen}
                                onClose={closeModopen}
                                aria-labelledby="modal-title"
                                aria-describedby="modal-description"
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                              >
                                <Box sx={style}>
                                  <Headroom>
                                    <Grid
                                      container
                                      style={{ backgroundColor: '#fff', position: 'sticky' }}
                                    >
                                      <Grid item xs={12}>
                                        <div
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                          }}
                                        >
                                          <DialogTitle
                                            style={{
                                              flex: 1,
                                              textAlign: 'center',
                                            }}
                                          >

                                            <Typography variant="h6" id="modal-title" style={{ textAlign: 'center', fontWeight: '600' }}>{selectedLabel}</Typography>
                                          </DialogTitle>
                                          <CancelOutlinedIcon
                                            onClick={closeModopen}
                                            fontSize="large"
                                            color="red"
                                            style={{
                                              color: 'red',
                                              paddingRight: '5px',
                                              cursor: 'pointer',
                                              // marginLeft: 'auto',
                                              // marginRight: '1.5rem'
                                            }}
                                          ></CancelOutlinedIcon>
                                        </div>
                                      </Grid>
                                    </Grid>


                                  </Headroom>
                                  {isLoading ? (
                                    <div style={{ margin: '5rem', textAlign: 'center' }}>
                                      <CircularProgress />
                                    </div>
                                  ) : (
                                    <div style={{ height: 420, padding: '0.1rem' }}>                           
                                      {selectedLabel === `Patient Info (${patientInfo})` &&
                                        raws !== null && raws.length > 0 && (
                                          <DataGrid
                                            rows={raws}
                                            rowHeight={28}
                                            columns={calumns}
                                            getRowId={(row) => row.id}
                                           classes={gridClasses}
                                            sx={gridRowStyle}
                                            pagination
                                            autoPageSize
                                          />
                                        )}
                                         {selectedLabel === `Approved (${approved1})` && raws2 !== null && raws2.length > 0 && (
                                        <DataGrid
                                          rows={raws2}
                                          rowHeight={28}
                                          columns={calumns2}
                                          getRowId={(row) => row.id}
                                          classes={gridClasses}
                                          pagination
                                          autoPageSize
                                          sx={gridRowStyle}
                                        />
                                      )}
                                      {selectedLabel === `Progress Notes (${progressNotes})` && raws1 !== null && raws1.length > 0 && (
                                        <DataGrid
                                          rows={raws1}
                                          rowHeight={28}
                                          columns={calumns1}
                                          getRowId={(row) => row.patientId}
                                          classes={gridClasses}
                                          pagination
                                          autoPageSize
                                          sx={gridRowStyle}
                                        />
                                      )}
                                      {selectedLabel === `Letter Of Medical Necessity (${letterOfMedicalNecessity})` && raws4 !== null && raws4.length > 0 && (
                                        <DataGrid
                                          rows={raws4}
                                          rowHeight={28}
                                          columns={calumns4}
                                          getRowId={(row) => row.id}
                                          classes={gridClasses}
                                          pagination
                                          autoPageSize
                                          sx={gridRowStyle}

                                        />
                                      )}
                                      {/* {selectedLabel === `Approved (${approved})` && raws2 !== null && raws2.length > 0 && (
                                        <DataGrid
                                          rows={raws2}
                                          rowHeight={28}
                                          columns={calumns2}
                                          getRowId={(row) => row.id}
                                          classes={gridClasses}
                                          pagination
                                          autoPageSize
                                          sx={gridRowStyle}
                                        />
                                      )} */}
                                      {selectedLabel === `Submitted to LuxBilling (${authRequest1})` && raws5 !== null && raws5.length > 0 && (
                                        <DataGrid
                                          rows={raws5}
                                          rowHeight={28}
                                          columns={calumns5}
                                          getRowId={(row) => row.id}
                                          classes={gridClasses}
                                          pagination
                                          autoPageSize
                                          sx={gridRowStyle}

                                        />
                                      )}
                                      {selectedLabel === `Questionnaire (${questionnaire})` && raws3 !== null && raws3.length > 0 && (
                                        <DataGrid
                                          rows={raws3}
                                          rowHeight={28}
                                          columns={calumns3}
                                          getRowId={(row) => row.id}
                                          classes={gridClasses}
                                          pagination
                                          autoPageSize
                                          sx={gridRowStyle}
                                        />
                                      )}
                                   

                                      {/* Add similar blocks for other labels */}
                                    </div>
                                  )}

                                </Box>
                              </Modal>
                            )}


                            {/* ////////// */}


                            {isModalOpen1 && (
                              <Modal
                                open={isModalOpen1}
                                onClose={closeModopen1}
                                aria-labelledby="modal-title"
                                aria-describedby="modal-description"
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                              >
                                <Box sx={style}>
                                  <Headroom>
                                    <Grid
                                      container
                                      style={{ backgroundColor: '#fff', position: 'sticky' }}
                                    >
                                      <Grid item xs={12}>
                                        <div
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                          }}
                                        >
                                          <DialogTitle
                                            style={{
                                              flex: 1,
                                              textAlign: 'center',
                                            }}
                                          >

                                            <Typography variant="h6" id="modal-title" style={{ textAlign: 'center', fontWeight: '600' }}>{selectedLabel1}</Typography>
                                          </DialogTitle>
                                          <CancelOutlinedIcon
                                            onClick={closeModopen1}
                                            fontSize="large"
                                            color="red"
                                            style={{
                                              color: 'red',
                                              paddingRight: '5px',
                                              cursor: 'pointer',
                                              // marginLeft: 'auto',
                                              // marginRight: '1.5rem'
                                            }}
                                          ></CancelOutlinedIcon>
                                        </div>
                                      </Grid>
                                    </Grid>


                                  </Headroom>
                                  {isLoading ? (
                                    <div style={{ margin: '5rem', textAlign: 'center' }}>
                                      <CircularProgress />
                                    </div>
                                  ) : (
                                    <div style={{ height: 420, padding: '0.1rem' }}>   
                                     

                                        {selectedLabel1 === `Pending Claims (${penndingclaims})` && panddingrows !== null && panddingrows.length > 0 && (
                                        <DataGrid
                                          rows={panddingrows}
                                          rowHeight={28}
                                          columns={panddingcalumns}
                                          getRowId={(row) => row.id}
                                          classes={gridClasses}
                                          pagination
                                          autoPageSize
                                          sx={gridRowStyle}

                                        />
                                      )}
                                       {selectedLabel1 === `Completed Claims (${compeletclaims})` && compeleterows !== null && compeleterows.length > 0 && (
                                        <DataGrid
                                          rows={compeleterows}
                                          rowHeight={28}
                                          columns={compeletedcalumns}
                                          getRowId={(row) => row.id}
                                          classes={gridClasses}
                                          pagination
                                          autoPageSize
                                          sx={gridRowStyle}

                                        />
                                      )}
                                    
                                     {selectedLabel1 === `Paid Claims (${paidclaims})` && paymentrows !== null && paymentrows.length > 0 && (
                                        <DataGrid
                                          rows={paymentrows}
                                          rowHeight={28}
                                          columns={paymentcalumns}
                                          getRowId={(row) => row.id}
                                          classes={gridClasses}
                                          pagination
                                          autoPageSize
                                          sx={gridRowStyle}

                                        />
                                      )}

                                      {/* Add similar blocks for other labels */}
                                    </div>
                                    
                                  )}

                                </Box>
                              </Modal>
                            )}
          </div>
        </div>


      )}



      {/* <Container fluid={true}>
          <Grid
            container
          >
            <Grid item xs={1}>
            <Checkbox
            value={isChecked}
            onChange={e => {
              setIsChecked(e.target.checked)
              console.log('Test', e.target.checked)
          }}
            />
            </Grid>
            <Grid item xs={9} style={{ fontWeight: 500, marginTop:'10px', marginLeft:'-40px'}}>
              <Typography fontSize='15px'>All</Typography>
            </Grid>
            <Grid item xs={4} style={{ fontWeight: 500 }}>
              <Typography fontSize='15px'>Date From</Typography>
            </Grid>
            <Grid item xs={4} style={{ fontWeight: 500 }}>
              <Typography fontSize='15px'>Date To</Typography>
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={4}>
              <Controller
                name='fromDate'
                // {...register('fromDate')}
                control={control}
                render={({ field }) => (
                  <TextField
                    // isClearable
                    size='small'
                    type='date'
                    disabled={isChecked == true?(true):(false)}
                    {...field}
                    fullWidth
                    value={fromDate}
                    // error={errors.fromDate}
                    onChange={e => {
                      setValue('fromDate', e.target.value)
                      setfromDate(e.target.value)

                    }}
                  />
                )}
              />

            </Grid>
            <Grid xs ={0.1}></Grid>
            <Grid item xs={4}>
              <Controller
                name='toDate'
                // {...register('toDate')}
                
                control={control}
                render={({ field }) => (
                  <TextField
                    type='date'
                    // isClearable
                    size='small'
                    {...field}
                    fullWidth
                    disabled={isChecked == true?(true):(false)}
                    value={toDate}
                    // onChange={e => settoDate(e.target.value)}
                    onChange={e => {
                      setValue('toDate', e.target.value)
                      settoDate(e.target.value)

                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                onClick={onFilterSearch}
                variant='contained'
                color='primary'
                style={{
                  float: 'right',
                  width: '150px',
                  height: '35px',

                  fontSize: '14px'
                }}
              >
                <SearchOutlinedIcon
                  fontSize='medium'
                  style={{ color: 'white', paddingBottom: '1px' }}
                ></SearchOutlinedIcon>
                Search
              </Button>
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
           </Container> */}  

    
    </React.Fragment>
  );
};
export default Dashboard;
